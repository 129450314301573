/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {GetCampaignsParamsADto, ListReferralsParamsADto} from '@cohort/admin-schemas/campaign';
import type {QueryOptions} from '@cohort/merchants/hooks/api/Query';
import {useCohortQuery} from '@cohort/merchants/hooks/api/Query';
import {
  getCampaign,
  getCampaignReferrals,
  getCampaignRevenue,
  getCampaigns,
  getChallengeSteps,
  getRedeemCodes,
} from '@cohort/merchants/lib/api/Campaigns';
import type {PaginationParamsDto} from '@cohort/shared/schema/common/pagination';

export const campaignsKeys = {
  campaigns: ['campaigns'] as const,
  list: (merchantId: string) => [...campaignsKeys.campaigns, merchantId, 'list'] as const,
  listWithFilters: (merchantId: string, params: GetCampaignsParamsADto) =>
    [...campaignsKeys.list(merchantId), params] as const,
  getById: (merchantId: string, campaignId: string) =>
    [...campaignsKeys.campaigns, merchantId, campaignId] as const,
  getCampaignRevenue: (merchantId: string, campaignId: string) =>
    [...campaignsKeys.getById(merchantId, campaignId), 'revenue'] as const,
  redeemCodes: (merchantId: string, campaignId: string) =>
    [...campaignsKeys.getById(merchantId, campaignId), 'redeem-codes'] as const,
  redeemCodesWithFilters: (merchantId: string, campaignId: string, params: PaginationParamsDto) =>
    [...campaignsKeys.redeemCodes(merchantId, campaignId), params] as const,
  challengeSteps: (merchantId: string, campaignId: string) =>
    [...campaignsKeys.getById(merchantId, campaignId), 'challenge-steps'] as const,
  referrals: (merchantId: string, campaignId: string, params: ListReferralsParamsADto) =>
    [...campaignsKeys.getById(merchantId, campaignId), params, 'referrals'] as const,
};

export const useCampaign = (merchantId: string, campaignId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: campaignsKeys.getById(merchantId, campaignId),
    queryFn: async () => getCampaign(merchantId, campaignId),
    ...options,
  });

export const useCampaignRevenue = (
  merchantId: string,
  campaignId: string,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: campaignsKeys.getCampaignRevenue(merchantId, campaignId),
    queryFn: async () => getCampaignRevenue(merchantId, campaignId),
    ...options,
  });

export const useCampaigns = (
  merchantId: string,
  params: GetCampaignsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: campaignsKeys.listWithFilters(merchantId, params),
    queryFn: async () => getCampaigns(merchantId, params),
    ...options,
    refetchInterval: options?.autoRefresh === true ? 5000 : false,
  });

export const useRedeemCodes = (
  merchantId: string,
  campaignId: string,
  params: PaginationParamsDto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: campaignsKeys.redeemCodesWithFilters(merchantId, campaignId, params),
    queryFn: async () => getRedeemCodes(merchantId, campaignId, params),
    ...options,
  });

export const useChallengeSteps = (merchantId: string, campaignId: string, options?: QueryOptions) =>
  useCohortQuery({
    queryKey: campaignsKeys.challengeSteps(merchantId, campaignId),
    queryFn: async () => getChallengeSteps(merchantId, campaignId),
    ...options,
  });

export const useReferralCompletions = (
  merchantId: string,
  campaignId: string,
  params: ListReferralsParamsADto,
  options?: QueryOptions
) =>
  useCohortQuery({
    queryKey: campaignsKeys.referrals(merchantId, campaignId, params),
    queryFn: async () => getCampaignReferrals(merchantId, campaignId, params),
    ...options,
  });
