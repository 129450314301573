import {DigitalAssetCollectionASchema} from '@cohort/admin-schemas/digitalAssetCollection';
import {OwnershipASchema} from '@cohort/admin-schemas/ownership';
import {PerkASchema} from '@cohort/admin-schemas/perk';
import {PerkAccessASchema} from '@cohort/admin-schemas/perkAccesses';
import {MAX_CAMPAIGN_SIZE} from '@cohort/shared/constants';
import {
  DatetimeSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import {RewardDistributionSchema} from '@cohort/shared/schema/common/rewards';
import {z} from 'zod';

export const TotalSupplySchema = z.number().int().min(1).max(MAX_CAMPAIGN_SIZE);

export const RewardConfigurationASchema = z.object({
  id: z.string().uuid(),
  title: LocalizedStringSchema,
  description: LocalizedRichTextSchema,
  imageFileKey: z.string().nullable(),
  videoFileKey: z.string().nullable(),
  distribution: RewardDistributionSchema,
  unlockedAt: DatetimeSchema.nullable(),
  collections: z.array(
    z.object({
      collectionId: z.string().uuid(),
      collection: DigitalAssetCollectionASchema,
      updatedAt: DatetimeSchema,
      totalSupply: z.number().nullable(),
      numClaimed: z.number(),
      availableSupply: z.number().nullable(),
    })
  ),
  perks: z.array(
    z.object({
      perkId: z.string().uuid(),
      revocable: z.boolean(),
      perk: PerkASchema,
      updatedAt: DatetimeSchema,
      totalSupply: z.number().nullable(),
      numClaimed: z.number(),
      availableSupply: z.number().nullable(),
    })
  ),
  totalSupply: z.number().nullable(),
  availableSupply: z.number().nullable(),
  numClaimed: z.number(),
  numOpenedBoxes: z.number(),
  showOdds: z.boolean(),
});
export type RewardConfigurationADto = z.infer<typeof RewardConfigurationASchema>;

export const UpdateRewardConfigurationASchema = z
  .object({
    title: LocalizedStringSchema,
    description: LocalizedRichTextSchema,
    imageFileKey: z.string().nullable(),
    videoFileKey: z.string().nullable(),
    distribution: RewardDistributionSchema,
    collections: z
      .array(
        z.object({
          collectionId: z.string().uuid(),
          totalSupply: TotalSupplySchema.nullable().optional(),
        })
      )
      .optional(),
    perks: z.array(
      z.object({
        perkId: z.string().uuid(),
        revocable: z.boolean().optional(),
        totalSupply: TotalSupplySchema.nullable().optional(),
      })
    ),
    totalSupply: TotalSupplySchema.nullable(),
    showOdds: z.boolean(),
  })
  .partial();

export const RewardBoxASchema = z.object({
  id: z.string(),
  userId: z.string(),
  locked: z.boolean(),
  openedAt: DatetimeSchema.nullable(),
  perkAccesses: z.array(PerkAccessASchema),
  ownerships: z.array(OwnershipASchema),
});
export type RewardBoxADto = z.infer<typeof RewardBoxASchema>;
