import ScoreView from '@cohort/merchants/apps/cohort-form/perkIntegrations/fillForm/ScoreView';
import Button from '@cohort/merchants/components/buttons/Button';
import {DialogBody, DialogContent, DialogFooter} from '@cohort/merchants/components/modals/Dialog';
import {Dialog} from '@cohort/merchants/components/modals/Dialog';
import type {CohortFormPromptType} from '@cohort/shared/schema/common/cohortForm';
import {formatDate} from '@cohort/shared/utils/format';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import {isImageFileUrl, isVideoFileUrl} from '@cohort/shared/utils/mimeTypes';
import VideoPlayer from '@cohort/shared-frontend/components/VideoPlayer';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Fragment, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

type CohortFormAnswerProps = {
  promptType: CohortFormPromptType;
  response: string | string[] | number | boolean | Date | null | undefined;
  mediaViewMode: 'inline' | 'modal';
};

export const CohortFormAnswer: React.FC<CohortFormAnswerProps> = ({
  promptType,
  response,
  mediaViewMode,
}) => {
  const {t} = useTranslation('app-cohort-form', {keyPrefix: 'components.cohortFormAnswer'});

  if (
    response === '' ||
    response === undefined ||
    response === null ||
    (Array.isArray(response) && response.length === 0)
  ) {
    return <p className="text-slate-400">{t('notAnswered')}</p>;
  }
  if (promptType === 'score') {
    return <ScoreView score={Number(response)} />;
  }
  const stringResponse = response.toString();
  if (promptType === 'date') {
    return formatDate(new Date(stringResponse));
  }

  if (promptType === 'media' && mediaViewMode === 'inline') {
    return <CohortFormMediaAnswer fileUrl={stringResponse} />;
  }
  if (promptType === 'media' && mediaViewMode === 'modal') {
    return <CohortFormMediaModalToggle fileUrl={stringResponse} />;
  }

  if (promptType === 'long-text') {
    return <CohortFormLongTextAnswer text={stringResponse} />;
  }

  if (promptType === 'picture-choice') {
    return <CohortFormPictureChoiceAnswer stringifiedImageFileKeys={stringResponse} />;
  }

  return stringResponse;
};

type CohortFormLongTextAnswerProps = {
  text: string;
};

const CohortFormLongTextAnswer: React.FC<CohortFormLongTextAnswerProps> = ({text}) => {
  const {t} = useTranslation('app-cohort-form', {keyPrefix: 'components.cohortFormAnswer'});

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const onTextRendered = useCallback((node: HTMLElement | null) => {
    if (node) {
      setIsTruncated(node.scrollHeight > node.clientHeight);
    }
  }, []);

  return (
    <div>
      <p
        ref={onTextRendered}
        className={cn(
          'line-clamp-3 overflow-hidden whitespace-pre-line text-wrap text-sm text-slate-700',
          isExpanded && 'line-clamp-none'
        )}
      >
        {text}
      </p>
      {isTruncated && (
        <Button variant="link" className="px-0" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? t('buttonShowLess') : t('buttonShowMore')}
        </Button>
      )}
    </div>
  );
};

type CohortFormMediaAnswerProps = {
  fileUrl: string;
  className?: string;
};

const CohortFormMediaAnswer: React.FC<CohortFormMediaAnswerProps> = ({fileUrl, className}) => {
  if (isImageFileUrl(fileUrl)) {
    return <img src={fileUrl} className={cn('w-full rounded-lg', className)} />;
  }
  if (isVideoFileUrl(fileUrl)) {
    return (
      <VideoPlayer
        videoSrc={fileUrl}
        className={cn('w-full overflow-hidden rounded-lg', className)}
      />
    );
  }
  return null;
};

type CohortFormPictureChoiceAnswerProps = {
  stringifiedImageFileKeys: string;
  className?: string;
};

const CohortFormPictureChoiceAnswer: React.FC<CohortFormPictureChoiceAnswerProps> = ({
  stringifiedImageFileKeys,
  className,
}) => {
  const imageFileKeys = stringifiedImageFileKeys.split(',');

  return (
    <div className="flex flex-wrap gap-2">
      {imageFileKeys.map(imageFileKey => (
        <img
          key={imageFileKey}
          src={getImageUrl(import.meta.env.COHORT_ENV, imageFileKey, {h: Sizes.XS, w: Sizes.XS})}
          className={cn('size-9 rounded-lg', className)}
        />
      ))}
    </div>
  );
};

type CohortFormMediaModalToggleProps = {
  fileUrl: string;
};

const CohortFormMediaModalToggle: React.FC<CohortFormMediaModalToggleProps> = ({fileUrl}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation('app-cohort-form', {keyPrefix: 'components.cohortFormAnswer'});
  const buttonText = isVideoFileUrl(fileUrl) ? t('buttonViewVideo') : t('buttonViewImage');

  return (
    <Fragment>
      <button
        className="flex h-[40px] w-fit flex-row items-center overflow-hidden rounded-md border border-gray-200 shadow-md"
        onClick={() => setOpen(true)}
      >
        <CohortFormMediaAnswer
          fileUrl={fileUrl}
          className="h-[40px] w-[60px] rounded-l-md rounded-r-none object-cover"
        />
        <p className="whitespace-nowrap px-2 text-xs font-medium text-slate-700">{buttonText}</p>
      </button>
      {open && <CohortFormMediaModal fileUrl={fileUrl} onClose={() => setOpen(false)} />}
    </Fragment>
  );
};

type CohortFormMediaModalProps = {
  fileUrl: string;
  onClose: () => void;
};

const CohortFormMediaModal: React.FC<CohortFormMediaModalProps> = ({fileUrl, onClose}) => {
  const {t} = useTranslation('app-cohort-form', {keyPrefix: 'components.cohortFormAnswer'});

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="[width:500px]">
        <DialogBody>
          <CohortFormMediaAnswer
            fileUrl={fileUrl}
            className="mt-10 w-full overflow-hidden rounded-md"
          />
        </DialogBody>
        <DialogFooter className="mt-8 flex w-full items-center justify-between gap-4">
          <Button variant="secondary" onClick={() => window.open(fileUrl, '_blank')}>
            {t('buttonDownload')}
          </Button>
          <Button variant="primary" onClick={onClose}>
            {t('buttonClose')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
