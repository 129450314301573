import type {TypeformAnswer, TypeformAnswerType} from '@cohort/shared/apps/typeform/common';
import dayjs from 'dayjs';
import {Trans} from 'react-i18next';
import {match} from 'ts-pattern';

const TypeformAnswerComponent: React.FC<{answer: TypeformAnswer}> = ({answer}) => {
  const answerType: TypeformAnswerType | undefined = answer.type;

  return match({answerType})
    .with({answerType: 'choice'}, () => answer.choice?.label || null)
    .with({answerType: 'choices'}, () => answer.choices?.labels?.join(', ') || null)
    .with({answerType: 'date'}, () => (answer.date ? dayjs(answer.date).format('LL') : null))
    .with({answerType: 'email'}, () => answer.email || null)
    .with({answerType: 'url'}, () =>
      answer.url ? (
        <a href={answer.url} target="_blank" rel="noopener noreferrer nofollow">
          {answer.url}
        </a>
      ) : null
    )
    .with({answerType: 'file_url'}, () => answer.file_url || null)
    .with({answerType: 'number'}, () => (answer.number ? String(answer.number) : null))
    .with({answerType: 'phone_number'}, () => answer.phone_number || null)
    .with({answerType: 'boolean'}, () =>
      // i18nOwl-ignore [app-typeform.utils.format.booleanAnswerFalse, app-typeform.utils.format.booleanAnswerTrue]
      answer.boolean === undefined ? null : (
        <Trans
          i18nKey={`utils.format.${answer.boolean ? 'booleanAnswerTrue' : 'booleanAnswerFalse'} `}
          ns="app-typeform"
        />
      )
    )

    .with({answerType: 'text'}, () => answer.text || null)
    .with({answerType: 'payment'}, () => answer.payment?.name || null)
    .with({answerType: undefined}, () => null)
    .exhaustive();
};

export default TypeformAnswerComponent;
