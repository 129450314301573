import type {CohortFormPromptType} from '@cohort/shared/schema/common/cohortForm';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {
  ArticleNyTimes,
  At,
  Calendar,
  CheckSquare,
  FileArrowUp,
  Hash,
  Image,
  ListDashes,
  Star,
  TextAa,
} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type CohortFormConfigProps = {
  type: CohortFormPromptType;
  textClassName?: string;
};

// i18nOwl-ignore [checkbox, date, email, long-text, media, number, picture-choice, select, score, text]
const CohortFormPromptTypeLabel: React.FC<CohortFormConfigProps> = ({type, textClassName}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.cohortFormPromptTypeLabel',
  });

  const Icon = match(type)
    .with('checkbox', () => CheckSquare)
    .with('date', () => Calendar)
    .with('email', () => At)
    .with('number', () => Hash)
    .with('select', () => ListDashes)
    .with('text', () => TextAa)
    .with('long-text', () => ArticleNyTimes)
    .with('score', () => Star)
    .with('media', () => FileArrowUp)
    .with('picture-choice', () => Image)
    .exhaustive();

  return (
    <div className="flex items-center gap-2">
      <Icon className="h-4 w-4 text-slate-400" />
      <p className={cn('font-normal text-slate-500', textClassName)}>{t(type)}</p>
    </div>
  );
};

export default CohortFormPromptTypeLabel;
