import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import type {TriggerIntegrationStepCompletionColumnsProps} from '@cohort/merchants/apps';
import {useTypeformForm} from '@cohort/merchants/apps/typeform/api';
import TypeformTakeQuizScoreDisplay from '@cohort/merchants/apps/typeform/triggerIntegrations/quiz/TypeformTakeQuizScoreDisplay';
import TypeformAnswerComponent from '@cohort/merchants/apps/typeform/utils/format';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {TypeformQuestionWithAnswerSchema} from '@cohort/shared/apps/typeform/common';
import type {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

type UseTypeformStepCompletionColumnsContext<T extends TriggerIntegrationStruct> =
  TriggerIntegrationStepCompletionColumnsProps<T> & {
    formIdConfigKey: keyof T['Config'];
    questionsWithAnswersKey: keyof T['VerificationAttemptData'];
    userScoreKey?: keyof T['VerificationAttemptData'];
    minScoreKey?: keyof T['VerificationAttemptData'];
  };

function useTypeformStepCompletionColumns<T extends TriggerIntegrationStruct>(
  context: UseTypeformStepCompletionColumnsContext<T>
): ColumnDef<StepCompletionADto, unknown>[] {
  const {
    columnHelper,
    formIdConfigKey,
    config,
    connectionId,
    userScoreKey,
    minScoreKey,
    questionsWithAnswersKey,
  } = context;
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-typeform', {
    keyPrefix: 'triggerIntegrations.useStepCompletionColumns',
  });
  if (!connectionId) {
    throw new Error('Connection ID is required');
  }
  const formId = config[formIdConfigKey];

  const {data: typeformConfig} = useTypeformForm(merchant.id, connectionId, String(formId), {
    enabled: formId !== undefined,
  });

  const fields = typeformConfig?.output.form.fields;
  const columns: ColumnDef<StepCompletionADto, unknown>[] = [];

  if (userScoreKey && minScoreKey) {
    columns.push(
      columnHelper.accessor(row => row.data, {
        header: t('score'),
        cell: data => {
          const value = data.getValue() as T['VerificationAttemptData'] | null;
          const userScore = z.number().safeParse(value?.[userScoreKey]);
          const minScore = z.number().safeParse(value?.[minScoreKey]);

          if (userScore.success && minScore.success) {
            return (
              <div className="p-2">
                <TypeformTakeQuizScoreDisplay userScore={userScore.data} minScore={minScore.data} />
              </div>
            );
          }
          return null;
        },
      })
    );
  }

  if (fields) {
    columns.push(
      ...fields.map(field =>
        columnHelper.accessor(row => row.data, {
          id: field.id,
          header: () => (
            <p className="line-clamp-1" title={field.title}>
              {field.title}
            </p>
          ),
          cell: data => {
            const value = data.getValue() as T['VerificationAttemptData'] | null;
            const questionsWithAnswers = z
              .array(TypeformQuestionWithAnswerSchema)
              .safeParse(value?.[questionsWithAnswersKey]);

            const answer = questionsWithAnswers.success
              ? questionsWithAnswers.data.find(
                  questionWithAnswer => questionWithAnswer.question.id === field.id
                )
              : undefined;

            if (answer?.answer !== undefined) {
              return <TypeformAnswerComponent answer={answer.answer} />;
            }
            return <p className="text-slate-500">{t('noAnswer')}</p>;
          },
        })
      )
    );
  }
  return columns;
}

export default useTypeformStepCompletionColumns;
