import type {PromptFormData} from '@cohort/merchants/apps/cohort-form/components/promptSheet/PromptForm';
import Button from '@cohort/merchants/components/buttons/Button';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {updateLocalizedString} from '@cohort/merchants/lib/Utils';
import type {Language} from '@cohort/shared/schema/common';
import {PlusCircle} from '@phosphor-icons/react';
import type {Control, FormState, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {get, useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type SelectFormSectionProps = {
  selectedLanguage: Language;
  control: Control<PromptFormData>;
  register: UseFormRegister<PromptFormData>;
  formState: FormState<PromptFormData>;
  setValue: UseFormSetValue<PromptFormData>;
};

const SelectFormSection: React.FC<SelectFormSectionProps> = ({
  selectedLanguage,
  formState,
  control,
  register,
  setValue,
}) => {
  const merchant = useCurrentMerchant();
  // i18nOwl-ignore [errorNoOptions]
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.selectFormSection',
  });

  const optionsError = get(formState, `errors.options`);
  const {fields, append, remove, update, replace} = useFieldArray({
    name: `options`,
    control,
  });
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <div className="relative flex flex-col space-y-2">
      <label className="block text-sm font-medium text-slate-700">{t('labelOptions')}</label>
      <DraggableList
        handleOnReorder={ids => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          replace(ids.map(id => fields[parseInt(id, 10)]!));
        }}
        items={fields.map((option, index) => {
          const error = get(formState, `errors.options.${index}.label.message`);

          return {
            item: (
              <DraggableListItem
                onRemove={
                  isDefaultLanguageSelected
                    ? () => {
                        remove(index);
                      }
                    : undefined
                }
                className="h-16 bg-slate-50"
              >
                <div className="flex w-full items-center">
                  <input
                    className="w-full rounded-md border border-border bg-white p-2 text-sm focus:ring-primary"
                    placeholder={option.label[merchant.defaultLanguage] ?? ''}
                    value={option.label[selectedLanguage] ?? ''}
                    onChange={e => {
                      const updatedOption = {...option};
                      updatedOption.label = updateLocalizedString(
                        selectedLanguage,
                        option.label,
                        e.target.value
                      );
                      if (selectedLanguage === merchant.defaultLanguage) {
                        updatedOption.value = e.target.value;
                      }
                      update(index, updatedOption);
                    }}
                  />
                  {error && (
                    <span className="m-2 text-sm font-normal text-red-500">{t(error)}</span>
                  )}
                </div>
              </DraggableListItem>
            ),
            id: String(index),
          };
        })}
      />
      {optionsError?.message !== undefined && (
        <span className="text-sm font-normal text-red-500">{t(optionsError.message)}</span>
      )}
      {isDefaultLanguageSelected && (
        <div className="flex w-full justify-between">
          <Button onClick={() => append({label: {}, value: ''})} variant="ghost" className="w-fit">
            <PlusCircle className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonAddOption')}
          </Button>
          <SwitchInput
            name="multipleChoice"
            register={register}
            control={control}
            label={t('labelMultipleChoice')}
            labelPosition="left"
            className="w-fit"
            onCheckedChange={e => {
              setValue('userPropertyId', null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SelectFormSection;
