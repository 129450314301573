import type {TriggerIntegration} from '@cohort/merchants/apps';
import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {CohortSwipeCardsVerificationAttemptDataSchema} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import Tooltip from '@cohort/shared-frontend/components/Tooltip';

const useCohortSwipeCardsTriggerIntegrationStepCompletionColumns: TriggerIntegration<CohortSwipeCardsTriggerStruct>['useStepCompletionColumns'] =
  ({columnHelper, config}) =>
    config.cards.map((card, index) =>
      columnHelper.accessor(row => row.data, {
        id: card.id,
        header: () => (
          <Tooltip
            content={
              <img
                className="my-1 h-40 w-40 rounded-md border border-border object-cover"
                src={getImageUrl(import.meta.env.COHORT_ENV, card.imageFileKey, {
                  h: Sizes.L,
                  w: Sizes.L,
                })}
              />
            }
            side="top"
            disabled={false}
          >
            <img
              className="h-6 w-6 rounded-md border border-border object-cover"
              src={getImageUrl(import.meta.env.COHORT_ENV, card.imageFileKey, {
                h: Sizes.S,
                w: Sizes.S,
              })}
            />
          </Tooltip>
        ),
        cell: data => {
          const value = CohortSwipeCardsVerificationAttemptDataSchema.parse(data.getValue());
          const answer = value.answers[card.id]?.answer;
          return answer;
        },
        enableSorting: false,
      })
    );

export default useCohortSwipeCardsTriggerIntegrationStepCompletionColumns;
