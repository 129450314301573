import {cn} from '@cohort/shared-frontend/utils/classNames';
import {useEffect, useState} from 'react';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProps,
  DroppableProvided,
} from 'react-beautiful-dnd';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';

/*
 Create a portal for the draggable list item to be rendered in
 fix this issue :
 https://github.com/atlassian/react-beautiful-dnd/issues/485#issuecomment-385816391
 solution from : 
 https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx
*/

const portal: HTMLElement = document.createElement('div');
portal.setAttribute('id', 'draggable-list-item-portal');
if (!document.getElementById('draggable-list-item-portal')) {
  document.body.appendChild(portal);
}

type PortalAwareItemProps = {
  provided: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  children: JSX.Element;
};

const PortalAwareItem: React.FC<PortalAwareItemProps> = ({children, provided, snapshot}) => {
  const usePortal: boolean = snapshot?.isDragging ?? false;

  const child = (
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
      {children}
    </div>
  );

  if (!usePortal) {
    return child;
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, portal);
};

export interface DraggableItem {
  id: string;
  item: JSX.Element;
  isDragDisabled?: boolean;
}
interface DraggableListProps {
  items: DraggableItem[];
  handleOnReorder?: (itemIds: string[]) => void;
  disabled?: boolean;
  className?: string;
}

const StrictModeDroppable: React.FC<DroppableProps> = ({children, ...props}) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const DraggableList: React.FC<DraggableListProps> = ({
  items,
  handleOnReorder,
  disabled,
  className,
}) => {
  const reorder = (
    list: DraggableItem[],
    startIndex: number,
    endIndex: number
  ): DraggableItem[] => {
    const results = [...list];
    const [removed] = results.splice(startIndex, 1) as [DraggableItem, ...DraggableItem[]];
    results.splice(endIndex, 0, removed);
    if (handleOnReorder) {
      handleOnReorder(results.map(item => item.id));
    }
    return results;
  };

  return (
    <DragDropContext
      onDragEnd={({source, destination}) => {
        if (!destination) {
          return;
        }
        reorder(items, source.index, destination.index);
      }}
    >
      <StrictModeDroppable droppableId="droppable" isDropDisabled={disabled}>
        {(provided: DroppableProvided) => (
          <div className={cn(className)} {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item: DraggableItem, index: number) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={item.isDragDisabled === true}
                >
                  {(provided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
                    <PortalAwareItem provided={provided} snapshot={draggableSnapshot}>
                      {item.item}
                    </PortalAwareItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export default DraggableList;
