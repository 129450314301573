import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {PerkIntegrationId} from '@cohort/shared/apps';
import {PerkIntegrationIdSchema} from '@cohort/shared/apps';
import {CohortQrCodePerkIntegrationSpec} from '@cohort/shared/apps/cohort/perks/qrCode';
import {CohortFormPerkIntegrationSpec} from '@cohort/shared/apps/cohort-form/perks/fillForm';
import {DiscordPrivateAccessPerkIntegrationSpec} from '@cohort/shared/apps/discord/perks/privateAccess';
import {ShopifyDiscountPerkIntegrationSpec} from '@cohort/shared/apps/shopify/perks/discount';
import {TalonOneCouponPerkIntegrationSpec} from '@cohort/shared/apps/talon-one/perks/coupon';
import {TypeformFormPerkIntegrationSpec} from '@cohort/shared/apps/typeform/perks/form';
import type {PerkType} from '@cohort/shared/schema/common/perks';
import {
  Calendar,
  CallBell,
  ChartBar,
  ChatsCircle,
  CurrencyCircleDollar,
  Desktop,
  LockSimple,
  PencilSimple,
  ShieldCheck,
  ShoppingCart,
  Star,
  Ticket,
  Users,
  UsersThree,
  Waveform,
  Wrench,
} from '@phosphor-icons/react';
import {useTranslation} from 'react-i18next';

export interface PerkTypeData {
  type: PerkType;
  perkIntegrations: PerkIntegrationId[];
  title: string;
  icon: JSX.Element;
  backgroundColor: string;
  perkTitlePlaceholder: string;
  perkDescriptionPlaceholder: string;
  iconColor: string;
}

interface Output {
  getPerkTypeData: (perkType: PerkType) => PerkTypeData;
  perkTypesData: PerkTypeData[];
}

export const usePerkUtils = (): Output => {
  const {t} = useTranslation('components', {
    keyPrefix: 'perks.usePerkUtils',
    lng: i18nComponentsXpsInstance.language,
    i18n: i18nComponentsXpsInstance,
  });

  const perkTypesData: PerkTypeData[] = [
    {
      type: 'custom',
      perkIntegrations: PerkIntegrationIdSchema.options,
      title: t('typeCustomTitle'),
      icon: <PencilSimple size={24} />,
      backgroundColor: 'bg-slate-100',
      perkTitlePlaceholder: t('typeCustomTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeCustomDescriptionPlaceholder'),
      iconColor: 'text-slate-500',
    },
    {
      type: 'limited-edition',
      perkIntegrations: [
        ShopifyDiscountPerkIntegrationSpec.id,
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
      ],
      title: t('typeLimitedEditionTitle'),
      icon: <ShoppingCart size={24} />,
      backgroundColor: 'bg-teal-100',
      perkTitlePlaceholder: t('typeLimitedEditionTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeLimitedEditionDescriptionPlaceholder'),
      iconColor: 'text-teal-500',
    },
    {
      type: 'custom-product',
      perkIntegrations: [
        ShopifyDiscountPerkIntegrationSpec.id,
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
      ],
      title: t('typeCustomProductTitle'),
      icon: <Wrench size={24} />,
      backgroundColor: 'bg-indigo-100',
      perkTitlePlaceholder: t('typeCustomProductTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeCustomProductDescriptionPlaceholder'),
      iconColor: 'text-indigo-500',
    },
    {
      type: 'exclusive-content',
      perkIntegrations: [],
      title: t('typeExclusiveContentTitle'),
      icon: <Wrench size={24} />,
      backgroundColor: 'bg-indigo-100',
      perkTitlePlaceholder: t('typeExclusiveContentTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeExclusiveContentDescriptionPlaceholder'),
      iconColor: 'text-indigo-500',
    },
    {
      type: 'discount',
      perkIntegrations: [
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
        ShopifyDiscountPerkIntegrationSpec.id,
        CohortQrCodePerkIntegrationSpec.id,
        TalonOneCouponPerkIntegrationSpec.id,
      ],
      title: t('typeDiscountTitle'),
      icon: <CurrencyCircleDollar size={24} />,
      backgroundColor: 'bg-pink-100',
      perkTitlePlaceholder: t('typeDiscountTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeDiscountDescriptionPlaceholder'),
      iconColor: 'text-pink-500',
    },
    {
      type: 'pre-sale',
      perkIntegrations: [
        ShopifyDiscountPerkIntegrationSpec.id,
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
      ],
      title: t('typePreSaleTitle'),
      icon: <Calendar size={24} />,
      backgroundColor: 'bg-primary/10',
      perkTitlePlaceholder: t('typePreSaleTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typePreSaleDescriptionPlaceholder'),
      iconColor: 'text-primary',
    },
    {
      type: 'private-sale',
      perkIntegrations: [
        ShopifyDiscountPerkIntegrationSpec.id,
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
      ],
      title: t('typePrivateSaleTitle'),
      icon: <ShieldCheck className="h-6 w-6" />,
      backgroundColor: 'bg-orange-100',
      perkTitlePlaceholder: t('typePrivateSaleTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typePrivateSaleDescriptionPlaceholder'),
      iconColor: 'text-orange-500',
    },
    {
      type: 'private-community',
      perkIntegrations: [
        DiscordPrivateAccessPerkIntegrationSpec.id,
        CohortQrCodePerkIntegrationSpec.id,
      ],
      title: t('typePrivateCommunityTitle'),
      icon: <LockSimple size={24} />,
      backgroundColor: 'bg-yellow-100',
      perkTitlePlaceholder: t('typePrivateCommunityTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typePrivateCommunityDescriptionPlaceholder'),
      iconColor: 'text-yellow-500',
    },
    {
      type: 'voting',
      perkIntegrations: [
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
        DiscordPrivateAccessPerkIntegrationSpec.id,
      ],
      title: t('typeVotingTitle'),
      icon: <ChartBar size={24} />,
      backgroundColor: 'bg-sky-100',
      perkTitlePlaceholder: t('typeVotingTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeVotingDescriptionPlaceholder'),
      iconColor: 'text-sky-500',
    },
    {
      type: 'survey',
      perkIntegrations: [
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
        DiscordPrivateAccessPerkIntegrationSpec.id,
      ],
      title: t('typeSurveyTitle'),
      icon: <ChatsCircle size={24} />,
      backgroundColor: 'bg-purple-100',
      perkTitlePlaceholder: t('typeSurveyTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeSurveyDescriptionPlaceholder'),
      iconColor: 'text-purple-500',
    },
    {
      type: 'event-ticket',
      perkIntegrations: [
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
        CohortQrCodePerkIntegrationSpec.id,
      ],
      title: t('typeEventTicketTitle'),
      icon: <Ticket size={24} />,
      backgroundColor: 'bg-amber-100',
      perkTitlePlaceholder: t('typeEventTicketTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeEventTicketDescriptionPlaceholder'),
      iconColor: 'text-amber-500',
    },
    {
      type: '1-1-meeting',
      perkIntegrations: [
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
        DiscordPrivateAccessPerkIntegrationSpec.id,
      ],
      title: t('type11MeetingTitle'),
      icon: <Users size={24} />,
      backgroundColor: 'bg-green-100',
      perkTitlePlaceholder: t('type11MeetingTitlePlaceholder'),
      perkDescriptionPlaceholder: t('type11MeetingDescriptionPlaceholder'),
      iconColor: 'text-green-500',
    },
    {
      type: 'webinar',
      perkIntegrations: [DiscordPrivateAccessPerkIntegrationSpec.id],
      title: t('typeWebinarTitle'),
      icon: <Waveform size={24} />,
      backgroundColor: 'bg-red-100',
      perkTitlePlaceholder: t('typeWebinarTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeWebinarDescriptionPlaceholder'),
      iconColor: 'text-red-500',
    },
    {
      type: 'livestream',
      perkIntegrations: [DiscordPrivateAccessPerkIntegrationSpec.id],
      title: t('typeLivestreamTitle'),
      icon: <Desktop size={24} />,
      backgroundColor: 'bg-emerald-100',
      perkTitlePlaceholder: t('typeLivestreamTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeLivestreamDescriptionPlaceholder'),
      iconColor: 'text-emerald-500',
    },
    {
      type: 'conciergerie',
      perkIntegrations: [],
      title: t('typeConciergerieTitle'),
      icon: <CallBell size={24} />,
      backgroundColor: 'bg-cyan-100',
      perkTitlePlaceholder: t('typeConciergerieTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeConciergerieDescriptionPlaceholder'),
      iconColor: 'text-cyan-500',
    },
    {
      type: 'VIP-support',
      perkIntegrations: [],
      title: t('typeVIPSupportTitle'),
      icon: <Star size={24} />,
      backgroundColor: 'bg-violet-100',
      perkTitlePlaceholder: t('typeVIPSupportTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeVIPSupportDescriptionPlaceholder'),
      iconColor: 'text-violet-500',
    },
    {
      type: 'community',
      perkIntegrations: [
        DiscordPrivateAccessPerkIntegrationSpec.id,
        CohortFormPerkIntegrationSpec.id,
        TypeformFormPerkIntegrationSpec.id,
      ],
      title: t('typeCommunityTitle'),
      icon: <UsersThree size={24} />,
      backgroundColor: 'bg-rose-100',
      perkTitlePlaceholder: t('typeCommunityTitlePlaceholder'),
      perkDescriptionPlaceholder: t('typeCommunityDescriptionPlaceholder'),
      iconColor: 'text-rose-500',
    },
  ];

  const getPerkTypeData = (perkType: PerkType): PerkTypeData =>
    perkTypesData.find(p => p.type === perkType) as PerkTypeData;

  return {
    perkTypesData,
    getPerkTypeData,
  };
};
