import type {UserEvent, UserEventDetailComponentProps} from '@cohort/merchants/apps';
import {formatDetailComponentBoldText} from '@cohort/merchants/apps/cohort/utils/format';
import {getCampaignRoute} from '@cohort/merchants/lib/Pages';
import type {CohortReferralCreatedEventStruct} from '@cohort/shared/apps/cohort/userEvents/referral/referralCreated';
import {CohortReferralCreatedEventSpec} from '@cohort/shared/apps/cohort/userEvents/referral/referralCreated';
import {Trans} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const DetailComponent: React.FC<UserEventDetailComponentProps> = ({userEvent}) => {
  const properties = userEvent.properties as CohortReferralCreatedEventStruct['Properties'];
  const {campaignId, campaignInternalName} = properties;
  const navigate = useNavigate();
  return (
    <Trans
      i18nKey="userEvents.referral.created.detail"
      ns="app-cohort"
      values={{
        campaignInternalName,
      }}
      components={{
        bold: formatDetailComponentBoldText(userEvent, () => {
          if (campaignId) {
            navigate(getCampaignRoute(campaignId).path);
          }
        }),
      }}
    />
  );
};

export const CohortReferralCreatedUserEvent: UserEvent<CohortReferralCreatedEventStruct> = {
  spec: CohortReferralCreatedEventSpec,
  detailComponent: DetailComponent,
};
