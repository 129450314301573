import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import InstagramEmbedPost from '@cohort/components-xps/components/apps/instagram/InstagramEmbedPost';
import type {TriggerIntegrationStepCompletionsGridComponentProps} from '@cohort/merchants/apps';
import {InViewLazyLoader} from '@cohort/merchants/components/InViewLazyLoader';
import type {
  InstagramTagInPostTriggerStruct,
  InstagramTagInPostVerificationAttemptData,
} from '@cohort/shared/apps/instagram/triggers/tagInPost';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

const InstagramTagInPostTriggerIntegrationStepCompletionsGridComponent: React.FC<
  TriggerIntegrationStepCompletionsGridComponentProps<InstagramTagInPostTriggerStruct>
> = ({stepCompletions}) => (
  <ResponsiveMasonry columnsCountBreakPoints={{0: 1, 1100: 2, 1500: 3}}>
    <Masonry gutter={'16px'}>
      {stepCompletions.map(triggerCompletion => (
        <InstagramPostCard key={triggerCompletion.id} triggerCompletion={triggerCompletion} />
      ))}
    </Masonry>
  </ResponsiveMasonry>
);

type InstagramPostCardProps = {
  triggerCompletion: StepCompletionADto;
};

const InstagramPostCard: React.FC<InstagramPostCardProps> = ({triggerCompletion}) => {
  const data = triggerCompletion.data as InstagramTagInPostVerificationAttemptData | null;
  const postUrl = data?.media?.permalink;

  return postUrl ? (
    <InViewLazyLoader className="min-h-[500px]">
      <InstagramEmbedPost postUrl={postUrl} />
    </InViewLazyLoader>
  ) : null;
};

export default InstagramTagInPostTriggerIntegrationStepCompletionsGridComponent;
