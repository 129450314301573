import type {CreateConnectionDataADto} from '@cohort/admin-schemas/connection';
import type {ConnectionEditComponentProps} from '@cohort/merchants/apps';
import Button from '@cohort/merchants/components/buttons/Button';
import ConnectionHeader from '@cohort/merchants/components/connections/ConnectionHeader';
import Input from '@cohort/merchants/components/form/input/Input';
import UrlInput from '@cohort/merchants/components/form/input/UrlInput';
import {connectionsKeys} from '@cohort/merchants/hooks/api/Connections';
import {useCohortMutation} from '@cohort/merchants/hooks/api/Query';
import {useUserSessionStore} from '@cohort/merchants/hooks/stores/userSession';
import {notify} from '@cohort/merchants/hooks/toast';
import {createConnection} from '@cohort/merchants/lib/api/Connections';
import type {MaxxingCredentials} from '@cohort/shared/apps/maxxing/connector';
import {MaxxingCredentialsSchema} from '@cohort/shared/apps/maxxing/connector';
import {REDACTED_FIELD} from '@cohort/shared/constants';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {zodResolver} from '@hookform/resolvers/zod';
import {useQueryClient} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

const MaxxingEditConnectionComponent: React.FC<ConnectionEditComponentProps> = ({
  existingConnection: connectionToEdit,
  onClose,
  showCancelBtn,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const merchantId = useUserSessionStore(store => store.merchantId!);
  const queryClient = useQueryClient();
  const {t} = useTranslation('app-maxxing', {
    keyPrefix: 'editConnectionComponent',
  });

  const {register, handleSubmit, control} = useForm<MaxxingCredentials>({
    resolver: zodResolver(MaxxingCredentialsSchema),
    defaultValues: {
      backOfficeApiUrl: connectionToEdit ? REDACTED_FIELD : undefined,
      computeApiUrl: connectionToEdit ? REDACTED_FIELD : undefined,
      accessToken: connectionToEdit ? REDACTED_FIELD : undefined,
      storeNumber: undefined,
    },
  });

  const handleError = (error: unknown): void => {
    if (isCohortError(error, 'connector.credentials-invalid')) {
      switch (error.context.cause) {
        case 'app.maxxing.invalid-api-url' satisfies CohortErrorCode:
          notify('error', t('errorInvalidApiUrl'));
          return;

        default:
          notify('error', t('errorInvalidCredentials'));
          return;
      }
    }
    notify('error', t('errorInvalidCredentials'));
  };

  const {isLoading, mutate: createOrFixConnectionMutation} = useCohortMutation({
    mutationFn: async (data: CreateConnectionDataADto) => createConnection(merchantId, data),
    notifySuccessMessage: t('createSuccessNotification'),
    onSuccess: async connection => {
      await queryClient.invalidateQueries(connectionsKeys.list(merchantId));
      onClose?.(connection);
    },
    onError: handleError,
  });

  return (
    <form
      onSubmit={e => {
        e.stopPropagation();
        handleSubmit(async data => {
          createOrFixConnectionMutation({
            connectorId: 'maxxing',
            name: null,
            credentials: {
              backOfficeApiUrl: data.backOfficeApiUrl,
              computeApiUrl: data.computeApiUrl,
              accessToken: data.accessToken,
              storeNumber: data.storeNumber,
            },
            existingConnectionId: connectionToEdit?.id,
          });
        })(e);
      }}
      className="space-y-6 p-6"
      data-testid="create-connection-form"
      id="create-connection-form"
    >
      <ConnectionHeader appId="maxxing" title={t('title')} />
      <UrlInput
        type="text"
        name="backOfficeApiUrl"
        label={t('labelBackOfficeApiUrl')}
        placeholder="example.cloud.maxxing.com"
        register={register}
        control={control}
      />
      <UrlInput
        type="text"
        name="computeApiUrl"
        label={t('labelComputeApiUrl')}
        placeholder="example.cloud.maxxing.com"
        register={register}
        control={control}
      />
      <Input
        type="text"
        name="accessToken"
        label={t('labelAccessToken')}
        placeholder="Basic ..."
        register={register}
        control={control}
      />
      <Input
        type="number"
        name="storeNumber"
        label={t('labelStoreNumber')}
        register={register}
        control={control}
      />
      <div className="flex justify-between">
        <Button type="submit" form="create-connection-form" loading={isLoading}>
          {connectionToEdit ? t('buttonReconnect') : t('buttonCreate')}
        </Button>

        {showCancelBtn && (
          <Button
            type="button"
            variant="secondary"
            className="flex gap-2"
            onClick={() => onClose?.(null)}
          >
            {t('buttonCancel')}
          </Button>
        )}
      </div>
    </form>
  );
};

export default MaxxingEditConnectionComponent;
