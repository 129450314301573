import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import type {TriggerIntegrationStepCompletionsGridComponentProps} from '@cohort/merchants/apps';
import {CohortFormAnswer} from '@cohort/merchants/apps/cohort-form/components/CohortFormAnswer';
import Separator from '@cohort/merchants/components/Separator';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import {CohortFillFormVerificationAttemptDataSchema} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import type {CohortFormAnswer as CohortFormAnswerType} from '@cohort/shared/schema/common/cohortForm';
import type {CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {formatDate} from '@cohort/shared/utils/format';
import Masonry from 'react-responsive-masonry';

export const CohortFillFormTriggerIntegrationStepCompletionsGridComponent: React.FC<
  TriggerIntegrationStepCompletionsGridComponentProps<CohortFillFormTriggerStruct>
> = ({config, stepCompletions}) => (
  <Masonry columnsCount={3} gutter={'16px'}>
    {stepCompletions.map(triggerCompletion => (
      <CohortFillFormVerificationAttemptCard
        key={triggerCompletion.id}
        triggerCompletion={triggerCompletion}
        config={config}
      />
    ))}
  </Masonry>
);

type CohortFillFormVerificationAttemptCardProps = {
  triggerCompletion: StepCompletionADto;
  config: CohortFormConfig;
};

const CohortFillFormVerificationAttemptCard: React.FC<
  CohortFillFormVerificationAttemptCardProps
> = ({triggerCompletion, config}) => {
  const data = CohortFillFormVerificationAttemptDataSchema.parse(triggerCompletion.data);
  return (
    <div className="flex w-full flex-col gap-4 rounded-md border border-border p-4 shadow-sm">
      <div className="flex flex-col gap-2">
        <p className="text-sm font-medium">{triggerCompletion.user.email}</p>
        <p className="text-xs text-muted-foreground">{formatDate(triggerCompletion.createdAt)}</p>
      </div>
      <Separator />
      {config.prompts.map(prompt => (
        <CohortFormAnswerDisplay
          prompt={prompt}
          answer={data.answers[prompt.id]?.answer}
          key={prompt.id}
        />
      ))}
    </div>
  );
};

type CohortFormAnswerDisplayProps = {
  prompt: CohortFormConfig['prompts'][number];
  answer: CohortFormAnswerType | undefined;
};
const CohortFormAnswerDisplay: React.FC<CohortFormAnswerDisplayProps> = ({prompt, answer}) => {
  const merchant = useCurrentMerchant();
  const promptName = prompt.name[merchant.defaultLanguage] ?? '';

  return (
    <div key={prompt.id} className="mb-1 mr-1 flex flex-col gap-1">
      <p className="text-sm font-medium text-muted-foreground">{promptName}</p>
      <CohortFormAnswer response={answer} promptType={prompt.type} mediaViewMode={'inline'} />
    </div>
  );
};
