import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import TikTokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import type {TriggerIntegrationStepCompletionsGridComponentProps} from '@cohort/merchants/apps';
import {InViewLazyLoader} from '@cohort/merchants/components/InViewLazyLoader';
import type {
  TikTokKeywordInDescriptionTriggerStruct,
  TikTokKeywordInDescriptionVerificationAttemptData,
} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

const TikTokKeywordInDescriptionTriggerIntegrationStepCompletionsGridComponent: React.FC<
  TriggerIntegrationStepCompletionsGridComponentProps<TikTokKeywordInDescriptionTriggerStruct>
> = ({stepCompletions}) => (
  <ResponsiveMasonry columnsCountBreakPoints={{0: 1, 1100: 2, 1500: 3}}>
    <Masonry>
      {stepCompletions.map(triggerCompletion => (
        <TikTokPostCard key={triggerCompletion.id} triggerCompletion={triggerCompletion} />
      ))}
    </Masonry>
  </ResponsiveMasonry>
);

type TikTokPostCardProps = {
  triggerCompletion: StepCompletionADto;
};

const TikTokPostCard: React.FC<TikTokPostCardProps> = ({triggerCompletion}) => {
  const data = triggerCompletion.data as TikTokKeywordInDescriptionVerificationAttemptData | null;
  const postId = data?.shareUrl?.split('/video/')[1]?.split('?')[0];

  return postId ? (
    <InViewLazyLoader className="min-h-[500px]">
      <TikTokEmbedPost videoId={postId} />
    </InViewLazyLoader>
  ) : null;
};

export default TikTokKeywordInDescriptionTriggerIntegrationStepCompletionsGridComponent;
