import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import TikTokLikeTiktokTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/likeTiktok/ConfigComponent';
import TikTokLikeTiktokTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/likeTiktok/StepCompletionTitleComponent';
import type {TikTokLikeTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';
import {TikTokLikeTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/likeTiktok';

export const TikTokLikeTiktokTriggerIntegration: TriggerIntegration<TikTokLikeTiktokTriggerStruct> =
  {
    spec: TikTokLikeTiktokTriggerSpec,
    configComponent: TikTokLikeTiktokTriggerConfigComponent,
    stepCompletionTitleComponent: TikTokLikeTiktokTriggerStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<TikTokLikeTiktokTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.like-tiktok.title', {ns: 'app-tiktok'}),
  };
