import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import {getFacebookPageInfoConfigComponent} from '@cohort/merchants/apps/facebook/triggers/FacebookPageInfoConfigComponent';
import FacebookLikePageTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/likePage/StepCompletionTitleComponent';
import type {FacebookLikePageTriggerStruct} from '@cohort/shared/apps/facebook/triggers/likePage';
import {FacebookLikePageTriggerSpec} from '@cohort/shared/apps/facebook/triggers/likePage';

export const FacebookLikePageTriggerIntegration: TriggerIntegration<FacebookLikePageTriggerStruct> =
  {
    spec: FacebookLikePageTriggerSpec,
    configComponent: getFacebookPageInfoConfigComponent({}),
    stepCompletionTitleComponent: FacebookLikePageTriggerIntegrationStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<FacebookLikePageTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggers.like-page.title', {ns: 'app-facebook'}),
  };
