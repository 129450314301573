import type {PromptFormData} from '@cohort/merchants/apps/cohort-form/components/promptSheet/PromptForm';
import RankBadge from '@cohort/merchants/components/badges/RankBadge';
import {
  FileInput,
  FileInputPreviewImage,
  FileInputUpload,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import Input from '@cohort/merchants/components/form/input/Input';
import LocalizedInput from '@cohort/merchants/components/form/input/LocalizedInput';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {Language} from '@cohort/shared/schema/common';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {PlusCircle, Trash} from '@phosphor-icons/react';
import type {FC} from 'react';
import React, {Fragment} from 'react';
import type {
  Control,
  FormState,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {get, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type PictureChoiceFormSectionInputProps = {
  control: Control<PromptFormData>;
  register: UseFormRegister<PromptFormData>;
  itemIndex: number;
  onDelete: () => void;
  selectedLanguage: Language;
};

const PictureChoiceFormSectionInput: FC<PictureChoiceFormSectionInputProps> = ({
  itemIndex,
  control,
  register,
  onDelete,
  selectedLanguage,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.pictureChoiceFormSection',
  });

  const showLabels = useWatch({
    name: 'showLabels',
    control,
  });

  return (
    <div className="h-28 w-full rounded-xl border border-slate-200 bg-slate-50">
      <div className="flex items-center justify-between space-x-4 p-4">
        <FileInput
          name={`pictureChoiceOptions.${itemIndex}.imageFileKey`}
          assetKind="pictureChoicePromptImage"
          control={control}
          register={register}
        >
          <div className="relative aspect-square size-20 rounded-xl border border-slate-200">
            <FileInputUpload
              assetKind="pictureChoicePromptImage"
              name={`pictureChoiceOptions.${itemIndex}.imageFileKey`}
              accept={AllowedAssetMimeTypes.pictureChoicePromptImage.options.join(',')}
            />
            <FileInputPreviewImage size={80} />
          </div>
        </FileInput>
        <div className="flex grow flex-col gap-y-4">
          <div className="flex w-full justify-between">
            <RankBadge rank={itemIndex + 1} size={20} />
            <Trash size={20} className="cursor-pointer text-red-500" onClick={onDelete} />
          </div>
          {showLabels && (
            <LocalizedInput
              type="text"
              name={`pictureChoiceOptions.${itemIndex}.label`}
              register={register}
              control={control}
              selectedLanguage={selectedLanguage}
              placeholder={t('pictureChoiceInputPlaceholder', {index: itemIndex + 1})}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type PictureChoiceFormSectionProps = {
  selectedLanguage: Language;
  control: Control<PromptFormData>;
  register: UseFormRegister<PromptFormData>;
  formState: FormState<PromptFormData>;
  setValue: UseFormSetValue<PromptFormData>;
  watch: UseFormWatch<PromptFormData>;
};
const PictureChoiceFormSection: React.FC<PictureChoiceFormSectionProps> = ({
  selectedLanguage,
  formState,
  control,
  register,
  setValue,
  watch,
}) => {
  const merchant = useCurrentMerchant();
  // i18nOwl-ignore [errorNoOptions]
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.pictureChoiceFormSection',
  });

  const optionsError = get(formState, `errors.pictureChoiceOptions`);
  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  const [
    pictureChoiceValue,
    multipleChoiceValue,
    maxMultipleChoiceTypeValue,
    maxMultipleChoicesValue,
  ] = watch([
    'pictureChoiceOptions',
    'multipleChoice',
    'maxMultipleChoiceType',
    'maxMultipleChoices',
  ]);

  const pictureChoiceOptions = pictureChoiceValue ?? [];

  const handlePictureDelete = (index: number): void => {
    const newOptions = pictureChoiceOptions.filter((_, i) => i !== index);
    setValue(`pictureChoiceOptions`, newOptions);
  };

  const fieldsList = pictureChoiceOptions.map((option, index) => {
    if (!option.imageFileKey) {
      return null;
    }

    const error = get(formState, `errors.pictureChoiceOptions.${index}.imageFileKey.message`);

    return (
      <div key={index}>
        <PictureChoiceFormSectionInput
          control={control}
          register={register}
          itemIndex={index}
          onDelete={() => handlePictureDelete(index)}
          selectedLanguage={selectedLanguage}
        />
        {error && <span className="text-sm font-normal text-red-500">{t(error)}</span>}
      </div>
    );
  });

  const nextImageIndex = pictureChoiceOptions.length;
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleAddPictureClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      setValue(`pictureChoiceOptions.${nextImageIndex}`, {imageFileKey: file});
    }
  };

  return (
    <div className="relative flex flex-col space-y-2">
      <label className="block text-sm font-medium text-slate-700">{t('pictureChoiceLabel')}</label>

      <div className="grid grid-cols-2 gap-2">
        {fieldsList}
        {isDefaultLanguageSelected && (
          <div
            className="flex h-28 w-full cursor-pointer items-center justify-center rounded-xl bg-slate-50"
            onClick={handleAddPictureClick}
          >
            <PlusCircle size={24} className="text-primary" />
          </div>
        )}
        <input
          type="file"
          accept={AllowedAssetMimeTypes.pictureChoicePromptImage.options.join(',')}
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      {optionsError?.message !== undefined && (
        <span className="text-sm font-normal text-red-500">{t(optionsError.message)}</span>
      )}

      <div className="flex flex-col gap-6 pt-6">
        <div className="flex w-full justify-between">
          <div className="w-full text-sm font-medium text-slate-700">{t('showLabels')}</div>
          <div className="w-10">
            <SwitchInput name="showLabels" register={register} control={control} />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex w-full justify-between">
            <div className="w-full text-sm font-medium text-slate-700">
              {t('multipleSelection')}
            </div>
            <div className="w-10">
              <SwitchInput name="multipleChoice" register={register} control={control} />
            </div>
          </div>

          {multipleChoiceValue && (
            <Fragment>
              <div className="grid w-full grid-cols-6 gap-x-3">
                <div className="col-span-2">
                  <SelectInput
                    name="maxMultipleChoiceType"
                    register={register}
                    control={control}
                    required
                    options={
                      [
                        {label: t('optionUnlimited'), value: 'unlimited'},
                        {label: t('optionMaxNumber'), value: 'exact-number'},
                      ] satisfies Array<{
                        label: string;
                        value: PromptFormData['maxMultipleChoiceType'];
                      }>
                    }
                  />
                </div>
                {maxMultipleChoiceTypeValue === 'exact-number' && (
                  <Input
                    className="col-span-1"
                    type="number"
                    name="maxMultipleChoices"
                    register={register}
                    control={control}
                    min={1}
                    defaultValue={1}
                  />
                )}
              </div>
              {maxMultipleChoicesValue && maxMultipleChoicesValue > pictureChoiceOptions.length && (
                <span className="text-sm font-normal text-red-500">
                  {t('maxMultipleChoicesTooHighWarning')}
                </span>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default PictureChoiceFormSection;
