import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import TikTokCommentTiktokTriggerConfigComponent from '@cohort/merchants/apps/tiktok/triggers/commentTikTok/ConfigComponent';
import TikTokCommentTiktokTriggerStepCompletionTitleComponent from '@cohort/merchants/apps/tiktok/triggers/commentTikTok/StepCompletionTitleComponent';
import type {TikTokCommentTiktokTriggerStruct} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';
import {TikTokCommentTiktokTriggerSpec} from '@cohort/shared/apps/tiktok/triggers/commentTiktok';

export const TikTokCommentTiktokTriggerIntegration: TriggerIntegration<TikTokCommentTiktokTriggerStruct> =
  {
    spec: TikTokCommentTiktokTriggerSpec,
    configComponent: TikTokCommentTiktokTriggerConfigComponent,
    stepCompletionTitleComponent: TikTokCommentTiktokTriggerStepCompletionTitleComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<TikTokCommentTiktokTriggerStruct>({
        ...context,
        userNameKey: 'username',
        getPostUrl: data => data.url,
      }),
    getTitle: t => t('triggers.comment-tiktok.title', {ns: 'app-tiktok'}),
  };
