import {RefereeASchema} from '@cohort/admin-schemas/campaign';
import {ChallengeStepASchema} from '@cohort/admin-schemas/challengeSteps';
import {TriggerIntegrationIdSchema} from '@cohort/shared/apps';
import {asArray, DatetimeSchema, EmailSchema} from '@cohort/shared/schema/common';
import {CampaignStatusSchema} from '@cohort/shared/schema/common/campaign';
import {ChallengeParticipationStatusSchema} from '@cohort/shared/schema/common/challengeParticipations';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {RewardDistributionSchema} from '@cohort/shared/schema/common/rewards';
import {z} from 'zod';

const StepCompletionAttemptASchema = z.object({
  stepId: z.string().uuid(),
  createdAt: DatetimeSchema,
  success: z.boolean(),
  data: z.unknown().nullable(),
  triggerIntegrationId: TriggerIntegrationIdSchema.nullable(),
  manual: z.boolean(),
  id: z.string().uuid(),
});
export type StepCompletionAttemptADto = z.infer<typeof StepCompletionAttemptASchema>;

export const ChallengeParticipationASchema = z.object({
  id: z.string().uuid(),
  createdAt: DatetimeSchema,
  completedAt: DatetimeSchema.nullable(),
  status: ChallengeParticipationStatusSchema,
  user: z.object({
    id: z.string().uuid(),
    email: EmailSchema,
    emailVerified: z.boolean(),
    isDeleted: z.boolean(),
  }),
  campaign: z.object({
    internalName: z.string(),
    imageFileKey: z.string().nullable(),
    id: z.string().uuid(),
    status: CampaignStatusSchema,
    rewardConfiguration: z.object({
      distribution: RewardDistributionSchema,
    }),
  }),
  challengeSteps: z.array(ChallengeStepASchema),
  stepCompletionAttempts: z.array(StepCompletionAttemptASchema),
  rewardBoxId: z.string().nullable(),
  referrer: z
    .object({
      userId: z.string().uuid(),
      email: EmailSchema,
      isDeleted: z.boolean(),
    })
    .nullable(),
  referees: z.array(RefereeASchema),
});
export type ChallengeParticipationADto = z.infer<typeof ChallengeParticipationASchema>;

export const MarkStepCompletedASchema = z.object({
  challengeStepId: z.string().uuid(),
});
export type MarkStepCompletedADto = z.infer<typeof MarkStepCompletedASchema>;

const ChallengeParticipationsOrderBySchema = z.enum([
  'createdAt',
  '-createdAt',
  'completedAt',
  '-completedAt',
]);
export type ChallengeParticipationsOrderBy = z.infer<typeof ChallengeParticipationsOrderBySchema>;

export const ChallengeParticipationsParamsASchema = PaginationParamsSchema.merge(
  z.object({
    campaignId: z.string().uuid().optional(),
    statuses: z.preprocess(asArray, z.array(ChallengeParticipationStatusSchema)).optional(),
    searchEmail: z.string().optional(),
    userId: z.string().uuid().optional(),
    orderBy: ChallengeParticipationsOrderBySchema.default('-createdAt'),
  })
);
export type ChallengeParticipationsParamsADto = z.infer<
  typeof ChallengeParticipationsParamsASchema
>;

export const GetParticipationAnalyticsParamsASchema = z.object({
  campaignId: z.string().uuid(),
});

export type GetParticipationAnalyticsParamsADto = z.infer<
  typeof GetParticipationAnalyticsParamsASchema
>;

export const ParticipationAnalyticsStepASchema = z.object({
  challengeStepId: z.string().uuid(),
  countCompleted: z.number().int().min(0),
});
export type ParticipationAnalyticsStepADto = z.infer<typeof ParticipationAnalyticsStepASchema>;

export const ParticipationAnalyticsASchema = z.object({
  countTotal: z.number().int().min(0),
  countCompleted: z.number().int().min(0),
  countCompletedBySteps: z.array(ParticipationAnalyticsStepASchema),
  countReferrals: z.number().int().min(0),
});

export type ParticipationAnalyticsADto = z.infer<typeof ParticipationAnalyticsASchema>;
