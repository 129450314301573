import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import InstagramLikeMediaTriggerIntegrationConfigComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/likeMedia/ConfigComponent';
import InstagramLikeMediaTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/likeMedia/StepCompletionTitleComponent';
import VerificationMessageStepCompletionContextComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/VerificationMessageStepCompletionContextComponent';
import type {InstagramLikeMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/likeMedia';
import {InstagramLikeMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/likeMedia';

export const InstagramLikeMediaTriggerIntegration: TriggerIntegration<InstagramLikeMediaTriggerStruct> =
  {
    spec: InstagramLikeMediaTriggerIntegrationSpec,
    configComponent: InstagramLikeMediaTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: InstagramLikeMediaTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent: VerificationMessageStepCompletionContextComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<InstagramLikeMediaTriggerStruct>({
        ...context,
        userNameKey: 'username',
      }),
    getTitle: t => t('triggerIntegrations.like-media.title', {ns: 'app-instagram'}),
  };
