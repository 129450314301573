import {getImageUrl, getVideoUrl, Sizes} from '@cohort/shared/utils/media';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Image} from '@phosphor-icons/react';
import React from 'react';

interface Props {
  digitalAssetImageFileKey: string | null;
  animationFileKey: string | null;
  size: number;
  className?: string;
}

export const DigitalAssetVisual: React.FC<Props> = ({
  digitalAssetImageFileKey,
  animationFileKey,
  className,
  size,
}) => {
  if (digitalAssetImageFileKey !== null && animationFileKey !== null) {
    return (
      <DigitalAssetVisualWrapper className={className}>
        <video controls={false} autoPlay playsInline loop muted height={size} width={size}>
          <source
            src={getVideoUrl(import.meta.env.COHORT_ENV, animationFileKey, {
              h: Sizes.S,
              w: Sizes.S,
            })}
          />
          <img
            title="nft"
            className="object-fit max-h-full max-w-full cursor-zoom-in"
            src={getImageUrl(import.meta.env.COHORT_ENV, digitalAssetImageFileKey, {
              h: Sizes.S,
              w: Sizes.S,
            })}
          />
        </video>
      </DigitalAssetVisualWrapper>
    );
  }
  if (digitalAssetImageFileKey !== null) {
    return (
      <DigitalAssetVisualWrapper className={className}>
        <img
          height={size}
          width={size}
          src={getImageUrl(import.meta.env.COHORT_ENV, digitalAssetImageFileKey, {
            h: Sizes.S,
            w: Sizes.S,
          })}
        />
      </DigitalAssetVisualWrapper>
    );
  }
  return (
    <DigitalAssetVisualWrapper className={className}>
      <div
        className="flex items-center justify-center bg-slate-50 text-slate-500"
        style={{height: size, width: size}}
      >
        <Image size={24} className="text-slate-400" />
      </div>
    </DigitalAssetVisualWrapper>
  );
};

const DigitalAssetVisualWrapper: React.FC<{
  children: JSX.Element;
  className?: string;
}> = ({children, className}) => (
  <div className={cn('relative flex-shrink-0 overflow-hidden', className)}>{children}</div>
);
