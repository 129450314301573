import {DigitalAssetCollectionASchema} from '@cohort/admin-schemas/digitalAssetCollection';
import {asArray, DatetimeSchema, EmailSchema} from '@cohort/shared/schema/common';
import {NetworkSchema} from '@cohort/shared/schema/common/blockchain';
import {
  OwnershipObtentionTypeSchema,
  OwnershipStatusSchema,
} from '@cohort/shared/schema/common/ownerships';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const NftASchema = z.object({
  smartContractAddress: z.string(),
  network: NetworkSchema,
  tokenId: z.number().int().min(1),
  transactionHash: z.string().nullable(),
  blockExplorerUrl: z.string().nullable(),
  openseaUrl: z.string().nullable(),
});
export type NftADto = z.infer<typeof NftASchema>;

export const DigitalAssetASchema = z.object({
  id: z.string().uuid(),
  tokenId: z.number().int().min(1).nullable(),
  imageFileKey: z.string(),
  animationFileKey: z.string().nullable(),
  nft: NftASchema.nullable(),
  digitalAssetCollection: DigitalAssetCollectionASchema,
});
export type DigitalAssetADto = z.infer<typeof DigitalAssetASchema>;

export const DigitalAssetAnalyticsASchema = DigitalAssetASchema.extend({
  user: z
    .object({
      id: z.string(),
      email: EmailSchema,
      isDeleted: z.boolean(),
    })
    .nullable(),
  ownership: z.object({
    status: OwnershipStatusSchema,
    obtentionType: OwnershipObtentionTypeSchema,
    obtentionDate: DatetimeSchema.nullable(),
  }),
  campaign: z
    .object({
      id: z.string().uuid(),
      internalName: z.string(),
    })
    .nullable(),
});
export type DigitalAssetAnalyticsADto = z.infer<typeof DigitalAssetAnalyticsASchema>;

export const DigitalAssetOrderByASchema = z.enum(['obtentionDate', '-obtentionDate']);
export type DigitalAssetOrderByADto = z.infer<typeof DigitalAssetOrderByASchema>;

export const GetDigitalAssetsParamsASchema = PaginationParamsSchema.extend({
  userId: z.preprocess(asArray, z.array(z.string().uuid()).optional()),
  digitalAssetCollectionId: z.preprocess(asArray, z.array(z.string().uuid()).optional()),
  orderBy: DigitalAssetOrderByASchema.default('-obtentionDate'),
});
export type GetDigitalAssetsParamsADto = z.infer<typeof GetDigitalAssetsParamsASchema>;

export const ExportDigitalAssetsParamsASchema = z.object({
  format: z.enum(['csv']).default('csv'),
  userId: z.preprocess(asArray, z.array(z.string().uuid()).optional()),
  digitalAssetCollectionId: z.string().uuid(),
});
export type ExportDigitalAssetsParamsADto = z.infer<typeof ExportDigitalAssetsParamsASchema>;
