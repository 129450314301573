import CohortFormMediaTypeLabel from '@cohort/merchants/apps/cohort-form/components/CohortFormMediaTypeLabel';
import CohortFormPromptTypeLabel from '@cohort/merchants/apps/cohort-form/components/CohortFormPromptTypeLabel';
import MandatoryFieldFormSection from '@cohort/merchants/apps/cohort-form/components/promptSheet/MandatoryFieldFormSection';
import PictureChoiceFormSection from '@cohort/merchants/apps/cohort-form/components/promptSheet/PictureChoiceFormSection';
import SelectFormSection from '@cohort/merchants/apps/cohort-form/components/promptSheet/SelectFormSection';
import UserPropertyFormSection from '@cohort/merchants/apps/cohort-form/components/promptSheet/UserPropertyFormSection';
import VisualFormSection from '@cohort/merchants/apps/cohort-form/components/promptSheet/VisualFormSection';
import Input from '@cohort/merchants/components/form/input/Input';
import {RadioCards} from '@cohort/merchants/components/form/RadioCards';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import LocalizedTextarea from '@cohort/merchants/components/form/textarea/LocalizedTextarea';
import Separator from '@cohort/merchants/components/Separator';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import {LocalizedFormSchema} from '@cohort/merchants/lib/form/localization';
import {fileValidatorOptional, fileValidatorRequired} from '@cohort/merchants/lib/form/validators';
import type {Language} from '@cohort/shared/schema/common';
import type {CohortFormConfig, CohortFormPrompt} from '@cohort/shared/schema/common/cohortForm';
import {
  CheckboxCohortFormPromptSchema,
  CohortFormMediaTypeSchema,
  CohortFormPictureChoiceOptionSchema,
  CohortFormPromptSchema,
  CohortFormPromptTypeSchema,
  DateCohortFormPromptSchema,
  EmailCohortFormPromptSchema,
  LongTextCohortFormPromptSchema,
  MediaCohortFormPromptSchema,
  NumberCohortFormPromptSchema,
  PictureChoiceCohortFormPromptSchema,
  ScoreCohortFormPromptSchema,
  SelectCohortFormPromptSchema,
  TextCohortFormPromptSchema,
} from '@cohort/shared/schema/common/cohortForm';
import set from 'lodash/set';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';

const OptionalImageFileKeySchema = {imageFileKey: z.any().refine(fileValidatorOptional)};

/* Fields used only in the form to handle custom logic */
const FormFieldsDataSchema = z.object({
  maxMultipleChoiceType: z
    .enum(['unlimited', 'exact-number'])
    .nullable()
    .default('unlimited')
    .optional(),
  hasSyncedProperty: z.boolean(),
  mediaType: z.enum(['image', 'video', 'imageOrVideo']).optional(),
  withVisual: z.boolean(),
});

/* 
  Union of all prompt types, using discriminatedUnion to make sure Zod will check the correct Schema based on the type,
  Overriding the imageFileKey for each schema, can't factorize using z.and, z.intersection, z.merge because of discriminatedUnion...
*/
const CohortFormPromptDataSchemaUnion = z
  .discriminatedUnion('type', [
    MediaCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    CheckboxCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    DateCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    EmailCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    LongTextCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    NumberCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    TextCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    ScoreCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    SelectCohortFormPromptSchema.extend(OptionalImageFileKeySchema),
    PictureChoiceCohortFormPromptSchema.extend({
      ...OptionalImageFileKeySchema,
      pictureChoiceOptions: z
        .array(
          CohortFormPictureChoiceOptionSchema.extend({
            imageFileKey: z.any().refine(fileValidatorRequired),
          })
        )
        .optional(),
    }),
  ])
  .superRefine((data, ctx) => {
    if (data.type === 'picture-choice') {
      if (data.pictureChoiceOptions === undefined || data.pictureChoiceOptions.length === 0) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorNoOptions',
          path: ['pictureChoiceOptions'],
        });
      }
    }
    if (data.type === 'select') {
      if (data.options === null || data.options.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'errorNoOptions',
          path: ['options'],
        });
      }
    }
  });

export const PromptFormDataSchema = CohortFormPromptDataSchemaUnion.and(FormFieldsDataSchema)
  .and(LocalizedFormSchema)
  .transform(data => {
    const {hasSyncedProperty, type, withVisual} = data;

    if (type === 'picture-choice') {
      if (!('multipleChoice' in data)) {
        set(data, 'multipleChoice', false);
        set(data, 'maxMultipleChoices', null);
      } else {
        if (data.multipleChoice && data.maxMultipleChoiceType === 'unlimited') {
          data.maxMultipleChoices = null; // null for unlimited
        } else if (data.multipleChoice && data.maxMultipleChoiceType === 'exact-number') {
          data.maxMultipleChoices = data.maxMultipleChoices ?? 1;
        } else {
          data.multipleChoice = false;
          data.maxMultipleChoices = null;
        }
      }
    }
    if (hasSyncedProperty === false) {
      data.userPropertyId = null;
    }
    if (!withVisual) {
      data.imageFileKey = null;
    }

    if ('referenceId' in data) {
      data.referenceId = data.referenceId === '' ? null : data.referenceId;
    }

    if ('userPropertyId' in data) {
      data.userPropertyId = data.userPropertyId === '' ? null : data.userPropertyId;
    }

    return data;
  });
export type PromptFormData = z.infer<typeof PromptFormDataSchema>;

type PromptFormProps = {
  selectedLanguage: Language;
  existingConfig?: CohortFormConfig | null;
  prompt?: CohortFormPrompt;
  onSave: (data: CohortFormPrompt) => void;
};

const PromptForm: React.FC<PromptFormProps> = ({
  selectedLanguage,
  existingConfig,
  prompt,
  onSave,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.promptForm',
  });
  const {register, control, handleSubmit, watch, setValue, formState} =
    useCohortForm<PromptFormData>();
  const [type, name] = watch(['type', 'name']);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;
  const isExistingPrompt = existingConfig?.prompts.some(
    existingPrompt => existingPrompt.id === prompt?.id
  );

  const promptTypesOptions = CohortFormPromptTypeSchema.options.map(type => ({
    label: <CohortFormPromptTypeLabel type={type} textClassName="text-slate-900" />,
    value: type,
  }));

  const mediaTypesOptions = CohortFormMediaTypeSchema.options.map(type => ({
    label: <CohortFormMediaTypeLabel type={type} />,
    value: type,
  }));

  return (
    <form
      id="perk-form-prompt"
      className="flex flex-col gap-6 overflow-y-auto px-6"
      onSubmit={e => {
        // otherwise it will submit the main perk form (dunno why since i'm using the form attribute in the button)
        e.stopPropagation();

        handleSubmit(async (data, assets) => {
          // Map uploaded assets to the form data.
          if (assets !== null) {
            assets.forEach((asset, assetPath) => {
              const assetFileKey = asset?.fileKey;
              if (assetFileKey !== undefined) {
                set(data, assetPath, assetFileKey);
              }
            });
          }

          const parsedPrompt = CohortFormPromptSchema.parse(data);

          onSave(parsedPrompt);
        })(e);
      }}
    >
      {isDefaultLanguageSelected && (
        <SelectInput
          name="type"
          label={t('labelType')}
          register={register}
          control={control}
          options={promptTypesOptions}
          isSearchable={false}
          onChange={() => {
            setValue('userPropertyId', null);
          }}
          disabled={isExistingPrompt}
        />
      )}
      <LocalizedTextarea
        name="name"
        label={t('titlePrompt')}
        placeholder={name[merchant.defaultLanguage] ?? t('placeholderPrompt')}
        rows={3}
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
      />

      {type === 'select' && (
        <SelectFormSection
          selectedLanguage={selectedLanguage}
          formState={formState}
          control={control}
          register={register}
          setValue={setValue}
        />
      )}
      {type === 'media' && (
        <RadioCards
          name="mediaType"
          direction="row"
          label={t('labelMediaType')}
          register={register}
          control={control}
          className="grid grid-cols-3 gap-4"
          options={mediaTypesOptions}
          withCheckIcon={false}
          disabled={isExistingPrompt}
        />
      )}
      {isDefaultLanguageSelected && (
        <Fragment>
          <Separator />
          {type === 'picture-choice' ? (
            <PictureChoiceFormSection
              selectedLanguage={selectedLanguage}
              formState={formState}
              control={control}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          ) : (
            <VisualFormSection register={register} control={control} />
          )}
          <Separator />
          <MandatoryFieldFormSection register={register} control={control} />
          {type !== 'picture-choice' && (
            <UserPropertyFormSection register={register} control={control} />
          )}
          <Separator />
          <Input
            type="text"
            name="referenceId"
            label={t('labelReferenceId')}
            optional
            register={register}
            control={control}
          />
        </Fragment>
      )}
    </form>
  );
};

export default PromptForm;
