import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import InstagramCommentMediaTriggerIntegrationConfigComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/commentMedia/ConfigComponent';
import InstagramCommentMediaTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/commentMedia/StepCompletionContextComponent';
import InstagramCommentMediaTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/instagram/triggerIntegrations/commentMedia/StepCompletionTitleComponent';
import type {InstagramCommentMediaTriggerStruct} from '@cohort/shared/apps/instagram/triggers/commentMedia';
import {InstagramCommentMediaTriggerIntegrationSpec} from '@cohort/shared/apps/instagram/triggers/commentMedia';

export const InstagramCommentMediaTriggerIntegration: TriggerIntegration<InstagramCommentMediaTriggerStruct> =
  {
    spec: InstagramCommentMediaTriggerIntegrationSpec,
    configComponent: InstagramCommentMediaTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent:
      InstagramCommentMediaTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      InstagramCommentMediaTriggerIntegrationStepCompletionContextComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<InstagramCommentMediaTriggerStruct>({
        ...context,
        userNameKey: 'username',
        getComment: data => data.comment?.text ?? null,
      }),
    getTitle: t => t('triggerIntegrations.comment-media.title', {ns: 'app-instagram'}),
  };
