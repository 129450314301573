import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import YoutubeCommentTriggerIntegrationConfigComponent from '@cohort/merchants/apps/youtube/triggers/comment/ConfigComponent';
import YoutubeCommentTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/youtube/triggers/comment/StepCompletionContextComponent';
import YoutubeCommentTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/youtube/triggers/comment/StepCompletionTitleComponent';
import type {YoutubeCommentTriggerStruct} from '@cohort/shared/apps/youtube/triggers/comment';
import {YoutubeCommentTriggerSpec} from '@cohort/shared/apps/youtube/triggers/comment';

export const YoutubeCommentTriggerIntegration: TriggerIntegration<YoutubeCommentTriggerStruct> = {
  spec: YoutubeCommentTriggerSpec,
  configComponent: YoutubeCommentTriggerIntegrationConfigComponent,
  stepCompletionTitleComponent: YoutubeCommentTriggerIntegrationStepCompletionTitleComponent,
  stepCompletionContextComponent: YoutubeCommentTriggerIntegrationStepCompletionContextComponent,
  useStepCompletionColumns: context =>
    useSocialStepCompletionColumns<YoutubeCommentTriggerStruct>({
      ...context,
      userNameKey: 'channelTitle',
      getComment: data => data.comment?.text ?? null,
    }),
  getTitle: t => t('triggerIntegrations.comment.title', {ns: 'app-youtube'}),
};
