import type {PromptFormData} from '@cohort/merchants/apps/cohort-form/components/promptSheet/PromptForm';
import PromptForm from '@cohort/merchants/apps/cohort-form/components/promptSheet/PromptForm';
import {PromptFormDataSchema} from '@cohort/merchants/apps/cohort-form/components/promptSheet/PromptForm';
import Button from '@cohort/merchants/components/buttons/Button';
import {CohortFormProvider} from '@cohort/merchants/components/form/CohortFormContext';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTitle,
} from '@cohort/merchants/components/modals/Sheet';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getDefinedLanguages} from '@cohort/merchants/lib/form/localization';
import type {Language} from '@cohort/shared/schema/common';
import type {
  CohortFormConfig,
  CohortFormPrompt,
  MediaCohortFormPrompt,
  PictureChoiceCohortFormPrompt,
  SelectCohortFormPrompt,
} from '@cohort/shared/schema/common/cohortForm';
import {X} from '@phosphor-icons/react';
import {useIsMutating} from '@tanstack/react-query';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {isDefined} from 'remeda';
import {v4 as uuid4} from 'uuid';

type PromptSheetProps = {
  title: string;
  prompt?: CohortFormPrompt;
  onClose: () => void;
  onSave: (data: CohortFormPrompt) => void;
  selectedLanguage: Language;
  existingConfig?: CohortFormConfig | null;
};

const PromptSheet: React.FC<PromptSheetProps> = ({
  title,
  prompt,
  onClose,
  onSave,
  selectedLanguage,
  existingConfig,
}) => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.promptSheet',
  });

  const defaultValues = {
    id: prompt?.id ?? uuid4(),
    referenceId: prompt?.referenceId ?? null,
    name: prompt?.name ?? {},
    type: prompt?.type ?? 'checkbox',
    mandatory: prompt?.mandatory ?? false,
    hasSyncedProperty: !(prompt === undefined || prompt.userPropertyId === null),
    userPropertyId: prompt?.userPropertyId ?? null,
    imageFileKey: prompt?.imageFileKey ?? null,
    selectedLanguage: selectedLanguage,
    defaultLanguage: merchant.defaultLanguage,
    definedLanguages: getDefinedLanguages(merchant.defaultLanguage, [
      existingConfig?.ctaText ?? {},
      prompt?.name ?? {},
      ...(prompt?.type === 'select' ? (prompt.options ?? []) : []).map(option => option.label),
      ...(prompt?.type === 'picture-choice' ? (prompt.pictureChoiceOptions ?? []) : []).map(
        option => option.label ?? {}
      ),
    ]),
    withVisual: isDefined(prompt?.imageFileKey),
    ...(prompt?.type === 'media' && {
      mediaType: (prompt as MediaCohortFormPrompt).mediaType,
    }),
    ...(prompt?.type === 'select' && {
      options: (prompt as SelectCohortFormPrompt).options ?? [],
    }),
    ...(prompt?.type === 'picture-choice' && {
      maxMultipleChoiceType: ((prompt as PictureChoiceCohortFormPrompt).maxMultipleChoices !== null
        ? 'exact-number'
        : 'unlimited') as PromptFormData['maxMultipleChoiceType'],
      maxMultipleChoices: (prompt as PictureChoiceCohortFormPrompt).maxMultipleChoices,
      pictureChoiceOptions: (prompt as PictureChoiceCohortFormPrompt).pictureChoiceOptions ?? [],
      showLabels: (prompt as PictureChoiceCohortFormPrompt).showLabels,
    }),
    ...(prompt?.type &&
      ['select', 'picture-choice'].includes(prompt.type) && {
        multipleChoice: (prompt as SelectCohortFormPrompt).multipleChoice,
      }),
  };

  const isMutating = useIsMutating({
    mutationKey: ['uploadAsset'],
  });
  const isLoading = isMutating > 0;

  // i18nOwl-ignore [errorInvalidConfig, errorNoOptions, errorRequired]
  return (
    <Sheet open onOpenChange={onClose}>
      <SheetContent className="max-h-100vh !grid h-[100vh] gap-0 bg-white [grid-template-rows:min-content_1fr_min-content]">
        <SheetTitle className="p-6">{title}</SheetTitle>
        <CohortFormProvider
          schema={PromptFormDataSchema}
          defaultValues={defaultValues}
          assets={[
            {
              name: 'imageFileKey',
              type: 'cohortFormPromptVisual',
            },
            {
              name: 'pictureChoiceOptions',
              nestedName: 'imageFileKey',
              type: 'pictureChoicePromptImage',
            },
          ]}
        >
          <PromptForm
            selectedLanguage={selectedLanguage}
            existingConfig={existingConfig}
            prompt={prompt}
            onSave={onSave}
          />
        </CohortFormProvider>
        <SheetFooter className="flex w-full !flex-row !justify-between border-t border-border px-6 py-4">
          <Button variant="secondary" onClick={onClose} data-testid="cancel">
            <X className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonCancel')}
          </Button>
          <Button type="submit" form="perk-form-prompt" loading={isLoading}>
            {prompt ? t('buttonEdit') : t('buttonAdd')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default PromptSheet;
