import type {TriggerIntegration} from '@cohort/merchants/apps';
import CohortSwipeCardsTriggerIntegrationConfigComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/ConfigComponent';
import CohortSwipeCardsTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/StepCompletionContextComponent';
import CohortSwipeCardsTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/StepCompletionTitleComponent';
import useCohortSwipeCardsTriggerIntegrationStepCompletionColumns from '@cohort/merchants/apps/cohort-form/triggerIntegrations/swipeCards/useStepCompletionColumns';
import {fileValidatorRequired} from '@cohort/merchants/lib/form/validators';
import type {CohortSwipeCardsTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {
  CohortSwipeCardsTriggerConfigSchema,
  CohortSwipeCardsTriggerIntegrationSpec,
} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import {LocalizedShortStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

const CohortSwipeCardsConfigFormSchema = CohortSwipeCardsTriggerConfigSchema.extend({
  cards: z
    .array(
      z.object({
        id: z.string().uuid(),
        title: LocalizedShortStringSchema.optional(),
        imageFileKey: z.any().refine(fileValidatorRequired),
      })
    )
    .min(1),
});
type CohortSwipeCardsConfigForm = z.infer<typeof CohortSwipeCardsConfigFormSchema>;
export const CohortSwipeCardsTriggerIntegration: TriggerIntegration<CohortSwipeCardsTriggerStruct> =
  {
    spec: CohortSwipeCardsTriggerIntegrationSpec,
    configComponent: CohortSwipeCardsTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: CohortSwipeCardsTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      CohortSwipeCardsTriggerIntegrationStepCompletionContextComponent,
    configAssets: [
      {
        name: 'cards',
        nestedName: 'imageFileKey',
        type: 'swipeCardImage',
      },
    ],
    configForm: {
      schema: CohortSwipeCardsConfigFormSchema,
      toForm: config => config,
      fromForm: (formValues: CohortSwipeCardsConfigForm) =>
        formValues as CohortSwipeCardsTriggerStruct['Config'],
    },
    useStepCompletionColumns: useCohortSwipeCardsTriggerIntegrationStepCompletionColumns,
    getTitle: t => t('triggerIntegrations.swipe-cards.title', {ns: 'app-cohort-form'}),
  };
