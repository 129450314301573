type RankBadgeProps = {
  rank: number;
  size?: number;
};

const RankBadge: React.FC<RankBadgeProps> = ({rank, size = 30}) => (
  <div className="flex rounded-md bg-slate-100" style={{width: size, height: size}}>
    <div className="m-auto text-xs font-semibold text-slate-500">{rank}</div>
  </div>
);

export default RankBadge;
