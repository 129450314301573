import {Star} from '@phosphor-icons/react';

type ScoreViewProps = {
  score: number;
};

const SCORE_VALUES = [1, 2, 3, 4, 5];

const ScoreView: React.FC<ScoreViewProps> = ({score}) => {
  if (!SCORE_VALUES.includes(score)) {
    return score;
  }
  return (
    <div className="flex gap-1">
      {SCORE_VALUES.map(value =>
        value <= score ? (
          <Star className="h-5 w-5 text-yellow-400" weight="fill" key={value} />
        ) : (
          <Star className="h-5 w-5 text-slate-300" weight="duotone" key={value} />
        )
      )}
    </div>
  );
};
export default ScoreView;
