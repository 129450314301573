import type {PromptFormData} from '@cohort/merchants/apps/cohort-form/components/promptSheet/PromptForm';
import {
  FileInput,
  FileInputImageCropper,
  FileInputUploader,
} from '@cohort/merchants/components/form/input/fileInput/FileInput';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type VisualFormSectionProps = {
  control: Control<PromptFormData>;
  register: UseFormRegister<PromptFormData>;
};
const VisualFormSection: React.FC<VisualFormSectionProps> = ({register, control}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.visualFormSection',
  });

  const {field: withVisualField} = useController({
    control,
    name: 'withVisual',
  });

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-grow flex-col gap-1">
          <label className="block text-sm font-medium text-slate-700">{t('titleVisual')}</label>
          <label className="text-xs text-slate-500">{t('subtitleVisual')}</label>
        </div>
        <div className="w-10">
          <SwitchInput name="withVisual" register={register} control={control} />
        </div>
      </div>
      {withVisualField.value && (
        <FileInput
          acceptHint={t('instructionFileInput')}
          assetKind="cohortFormPromptVisual"
          name="imageFileKey"
          control={control}
          register={register}
        >
          <FileInputUploader />
          <FileInputImageCropper />
        </FileInput>
      )}
    </div>
  );
};

export default VisualFormSection;
