import type {PromptFormData} from '@cohort/merchants/apps/cohort-form/components/promptSheet/PromptForm';
import SelectInput from '@cohort/merchants/components/form/select/SelectInput';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Separator from '@cohort/merchants/components/Separator';
import {usePaginatedUserProperties} from '@cohort/merchants/hooks/api/UserProperties';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {UserPropertyDataType} from '@cohort/shared/schema/common/userProperty';
import {Fragment, useState} from 'react';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useController} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

type UserPropertyFormSectionProps = {
  control: Control<PromptFormData>;
  register: UseFormRegister<PromptFormData>;
};
const UserPropertyFormSection: React.FC<UserPropertyFormSectionProps> = ({register, control}) => {
  const merchant = useCurrentMerchant();
  const [search, setSearch] = useState('');
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.userPropertyFormSection',
  });

  const {field: hasSyncedProperty} = useController({name: 'hasSyncedProperty', control});
  const {field: promptType} = useController({name: 'type', control});
  const {field: multipleChoice} = useController({name: 'multipleChoice', control});

  const acceptedPropertyDataType = match({
    type: promptType.value,
    multipleChoice: multipleChoice.value ?? false,
  })
    .with({type: 'number'}, () => 'number' as const)
    .with({type: 'text'}, () => 'string' as const)
    .with({type: 'long-text'}, () => null)
    .with({type: 'media'}, () => null)
    .with({type: 'email'}, () => 'string' as const)
    .with({type: 'date'}, () => 'date' as const)
    .with({type: 'checkbox'}, () => 'boolean' as const)
    .with({type: 'select', multipleChoice: true}, () => 'string_list' as const)
    .with({type: 'select', multipleChoice: false}, () => 'string' as const)
    .with({type: 'score'}, () => 'number' as const)
    .with({type: 'picture-choice', multipleChoice: true}, () => 'string_list' as const)
    .with({type: 'picture-choice', multipleChoice: false}, () => 'string' as const)
    .exhaustive() satisfies UserPropertyDataType | null;

  // user properties are searchable because pagination can't be done in a selector
  const {data} = usePaginatedUserProperties(
    merchant.id,
    {
      page: 1,
      pageSize: 20,
      search: search === '' ? undefined : search,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataTypes: [acceptedPropertyDataType!],
      hasAppId: false,
      orderBy: 'name',
    },
    {enabled: acceptedPropertyDataType !== null}
  );

  const [, userProperties] = data ?? [];

  if (acceptedPropertyDataType === null) {
    return null;
  }

  const userPropertiesOptions =
    userProperties?.map(userProperty => ({
      label: userProperty.name,
      value: userProperty.id,
    })) ?? [];

  // i18nOwl-ignore [propertyType.boolean, propertyType.date, propertyType.number, propertyType.string, propertyType.string_list]
  const dataTypeLabel = t(`propertyType.${acceptedPropertyDataType}`);

  return (
    <Fragment>
      <Separator />
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-grow flex-col gap-1">
          <label className="block text-sm font-medium text-slate-700">
            {t('titleSyncProperty')}
          </label>
          <label className="text-xs text-slate-500">{t('subtitleSyncProperty')}</label>
        </div>
        <div className="w-10">
          <SwitchInput name="hasSyncedProperty" register={register} control={control} />
        </div>
      </div>
      {hasSyncedProperty.value === true && (
        <Fragment>
          <SelectInput
            options={userPropertiesOptions}
            name="userPropertyId"
            label={t('labelSelectProperty')}
            register={register}
            control={control}
            onInputChange={setSearch}
            inputChangeMode="debounced"
          />
          <HighlightText
            text={
              <Trans
                i18nKey="components.promptSheet.userPropertyFormSection.canAssociateWith"
                ns="app-cohort-form"
                values={{
                  propertyType: dataTypeLabel,
                }}
                components={{
                  bold: <span className="font-medium" />,
                }}
              />
            }
            type="info"
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserPropertyFormSection;
