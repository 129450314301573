import type {TriggerIntegration} from '@cohort/merchants/apps';
import useSocialStepCompletionColumns from '@cohort/merchants/apps/common/triggers/useSocialStepCompletionColumns';
import FacebookCommentPostTriggerIntegrationConfigComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/ConfigComponent';
import FacebookCommentPostTriggerIntegrationStepCompletionContextComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/StepCompletionContextComponent';
import FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent from '@cohort/merchants/apps/facebook/triggers/commentPost/StepCompletionTitleComponent';
import type {FacebookCommentPostTriggerStruct} from '@cohort/shared/apps/facebook/triggers/commentPost';
import {FacebookCommentPostTriggerSpec} from '@cohort/shared/apps/facebook/triggers/commentPost';

export const FacebookCommentPostTriggerIntegration: TriggerIntegration<FacebookCommentPostTriggerStruct> =
  {
    spec: FacebookCommentPostTriggerSpec,
    configComponent: FacebookCommentPostTriggerIntegrationConfigComponent,
    stepCompletionTitleComponent: FacebookCommentPostTriggerIntegrationStepCompletionTitleComponent,
    stepCompletionContextComponent:
      FacebookCommentPostTriggerIntegrationStepCompletionContextComponent,
    useStepCompletionColumns: context =>
      useSocialStepCompletionColumns<FacebookCommentPostTriggerStruct>({
        ...context,
        userNameKey: 'username',
        getComment: data => data.comment?.message ?? null,
      }),
    getTitle: t => t('triggers.comment-post.title', {ns: 'app-facebook'}),
  };
