// i18nOwl-ignore [app-maxxing.description,app-maxxing.sync.description,app-maxxing.sync.title]
import type {App} from '@cohort/merchants/apps';
import MaxxingEditConnectionComponent from '@cohort/merchants/apps/maxxing/EditConnectionComponent';
import translationEn from '@cohort/merchants/apps/maxxing/locales/en.json';
import translationFr from '@cohort/merchants/apps/maxxing/locales/fr.json';
import {MaxxingSyncIntegration} from '@cohort/merchants/apps/maxxing/sync/SyncIntegration';
import type {MaxxingAppStruct} from '@cohort/shared/apps/maxxing';
import {MaxxingAppSpec} from '@cohort/shared/apps/maxxing';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const MaxxingApp: App<MaxxingAppStruct> = {
  spec: MaxxingAppSpec,
  ConnectionEditComponent: MaxxingEditConnectionComponent,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="maxxing" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [],
  triggerIntegrations: [],
  userEvents: [],
  sync: MaxxingSyncIntegration,
};

export default MaxxingApp;
