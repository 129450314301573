import type {PromptFormData} from '@cohort/merchants/apps/cohort-form/components/promptSheet/PromptForm';
import SwitchInput from '@cohort/merchants/components/form/SwitchInput';
import type {Control, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type MandatoryFieldFormSectionProps = {
  control: Control<PromptFormData>;
  register: UseFormRegister<PromptFormData>;
};

const MandatoryFieldFormSection: React.FC<MandatoryFieldFormSectionProps> = ({
  register,
  control,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'components.promptSheet.mandatoryFieldFormSection',
  });

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex flex-grow flex-col gap-1">
        <label className="block text-sm font-medium text-slate-700">{t('titleMandatory')}</label>
        <label className="text-xs text-slate-500">{t('subtitleMandatory')}</label>
      </div>
      <div className="w-10">
        <SwitchInput name="mandatory" register={register} control={control} />
      </div>
    </div>
  );
};

export default MandatoryFieldFormSection;
