import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import type {TriggerIntegrationStepCompletionColumnsProps} from '@cohort/merchants/apps';
import Button from '@cohort/merchants/components/buttons/Button';
import type {TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {ArrowSquareOut} from '@phosphor-icons/react';
import type {ColumnDef} from '@tanstack/react-table';
import {useTranslation} from 'react-i18next';

type useSocialStepCompletionColumnsContext<T extends TriggerIntegrationStruct> =
  TriggerIntegrationStepCompletionColumnsProps<T> & {
    userNameKey: keyof T['VerificationAttemptData'];
    getComment?: (data: T['VerificationAttemptData']) => string | null;
    getPostUrl?: (data: T['VerificationAttemptData']) => string | null;
  };

function useSocialStepCompletionColumns<T extends TriggerIntegrationStruct>(
  context: useSocialStepCompletionColumnsContext<T>
): ColumnDef<StepCompletionADto, unknown>[] {
  const {columnHelper, userNameKey, getPostUrl, getComment} = context;
  const {t} = useTranslation('apps', {
    keyPrefix: 'common.triggers.useSocialStepCompletionColumns',
  });

  const columns: ColumnDef<StepCompletionADto, unknown>[] = [];

  columns.push(
    ...[
      columnHelper.accessor(row => row.data, {
        header: t('username'),
        cell: data => {
          const value = data.getValue() as T['VerificationAttemptData'] | null;
          return value?.[userNameKey] ?? null;
        },
        enableSorting: false,
      }),
    ]
  );

  if (getComment) {
    columns.push(
      columnHelper.accessor(row => row.data, {
        header: t('comment'),
        cell: data => {
          const value = data.getValue() as T['VerificationAttemptData'] | null;
          return value ? getComment(value) : null;
        },
        enableSorting: false,
      })
    );
  }

  if (getPostUrl) {
    columns.push(
      columnHelper.accessor(row => row.data, {
        header: t('post'),
        cell: data => {
          const value = data.getValue() as T['VerificationAttemptData'] | null;
          const commentUrl = getPostUrl(value);

          return commentUrl ? (
            <Button variant="secondary" onClick={() => window.open(commentUrl, '_blank')}>
              <ArrowSquareOut className="-ml-1 mr-2 h-5 w-5" />
              {t('viewPost')}
            </Button>
          ) : null;
        },
        enableSorting: false,
      })
    );
  }

  return columns;
}
export default useSocialStepCompletionColumns;
