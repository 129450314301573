import type {ConnectionADto} from '@cohort/admin-schemas/connection';
import type {PerkUsageADto} from '@cohort/admin-schemas/perkAccesses';
import type {StepCompletionADto} from '@cohort/admin-schemas/stepCompletions';
import type {UserEventADto} from '@cohort/admin-schemas/userEvents';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import BazaarvoiceApp from '@cohort/merchants/apps/bazaarvoice/BazaarvoiceApp';
import BrevoApp from '@cohort/merchants/apps/brevo/BrevoApp';
import CohortApp from '@cohort/merchants/apps/cohort/CohortApp';
import {CohortBadgeGainedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/badgeGained/UserEvent';
import {CohortBadgeLostUserEvent} from '@cohort/merchants/apps/cohort/userEvents/badgeLost/UserEvent';
import {CohortChallengeCompletedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/challengeCompleted/UserEvent';
import {CohortChallengeLeftUserEvent} from '@cohort/merchants/apps/cohort/userEvents/challengeLeft/UserEvent';
import {CohortChallengeOptedInUserEvent} from '@cohort/merchants/apps/cohort/userEvents/challengeOptedIn/UserEvent';
import {CohortChallengeStepCompletedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/challengeStepCompleted/UserEvent';
import {CohortContentAvailableUserEvent} from '@cohort/merchants/apps/cohort/userEvents/contentAvailable/UserEvent';
import {CohortContentViewedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/contentViewed/UserEvent';
import {CohortDigitalAssetReceivedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/digitalAssetReceived/UserEvent';
import {CohortDigitalAssetTransferredUserEvent} from '@cohort/merchants/apps/cohort/userEvents/digitalAssetTransferred/UserEvent';
import {CohortNewChallengeAvailableUserEvent} from '@cohort/merchants/apps/cohort/userEvents/newChallengeAvailable/UserEvent';
import {CohortNewChallengeStepAvailableUserEvent} from '@cohort/merchants/apps/cohort/userEvents/newChallengeStepAvailable/UserEvent';
import {CohortPerkReceivedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/perkReceived/UserEvent';
import {CohortPerkUsedUserEvent} from '@cohort/merchants/apps/cohort/userEvents/perkUsed/UserEvent';
import CohortFormApp from '@cohort/merchants/apps/cohort-form/CohortFormApp';
import ComarchApp from '@cohort/merchants/apps/comarch/ComarchApp';
import DiscordApp from '@cohort/merchants/apps/discord/DiscordApp';
import FacebookApp from '@cohort/merchants/apps/facebook/FacebookApp';
import GarminConnectApp from '@cohort/merchants/apps/garmin-connect/GarminConnectApp';
import GoogleFitApp from '@cohort/merchants/apps/google-fit/GoogleFitApp';
import InstagramApp from '@cohort/merchants/apps/instagram/InstagramApp';
import KlaviyoApp from '@cohort/merchants/apps/klaviyo/KlaviyoApp';
import MaxxingApp from '@cohort/merchants/apps/maxxing/MaxxingApp';
import PostgresqlApp from '@cohort/merchants/apps/postgresql/PostgresqlApp';
import SalesforceApp from '@cohort/merchants/apps/salesforce/SalesforceApp';
import ShopifyApp from '@cohort/merchants/apps/shopify/ShopifyApp';
import SpotifyApp from '@cohort/merchants/apps/spotify/SpotifyApp';
import StravaApp from '@cohort/merchants/apps/strava/StravaApp';
import TalonOneApp from '@cohort/merchants/apps/talon-one/TalonOneApp';
import TikTokApp from '@cohort/merchants/apps/tiktok/TikTokApp';
import TwitterApp from '@cohort/merchants/apps/twitter/TwitterApp';
import TypeformApp from '@cohort/merchants/apps/typeform/TypeformApp';
import YoutubeApp from '@cohort/merchants/apps/youtube/YoutubeApp';
import type {
  StepTriggerFormType,
  StepTriggerType,
} from '@cohort/merchants/pages/campaigns/campaign/edit/settings/challenge/utils';
import type {AppId, ResourceType} from '@cohort/shared/apps';
import {EventIdSchema, NotificationIntegrationIdSchema} from '@cohort/shared/apps';
import type {
  AppSpec,
  AppStruct,
  NotificationIntegrationSpec,
  NotificationIntegrationStruct,
  PerkIntegrationSpec,
  PerkIntegrationStruct,
} from '@cohort/shared/apps/app';
import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import type {SyncIntegrationSpec, SyncIntegrationStruct} from '@cohort/shared/apps/sync';
import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import type {Language} from '@cohort/shared/schema/common';
import type {AdminAssetKind} from '@cohort/shared/schema/common/assets';
import type {NotificationConfigurationResourceType} from '@cohort/shared/schema/common/notificationConfiguration';
import {NotificationConfigurationResourceTypeSchema} from '@cohort/shared/schema/common/notificationConfiguration';
import type {ColumnDef, ColumnHelper} from '@tanstack/react-table';
import type {ResourceLanguage, TFunction} from 'i18next';
import type React from 'react';
import type {Path, UseFormReturn} from 'react-hook-form';
import {compact} from 'remeda';
import {z} from 'zod';

export type ConnectionEditComponentProps = {
  existingConnection?: ConnectionADto;
  autoStartOauthFlow?: boolean;
  onClose?: (connection: ConnectionADto | null) => void;
  showCancelBtn: boolean;
};

export type PerkUsageViewComponentProps = {
  usage: PerkUsageADto;
};

export type App<T extends AppStruct = AppStruct> = {
  spec: AppSpec<T>;
  ConnectionEditComponent: React.FC<ConnectionEditComponentProps> | null;
  locales: {
    en: ResourceLanguage;
    fr: ResourceLanguage;
  };
  logo: React.ReactElement;
  medias: ContentMedia[];
  notificationIntegrations: NotificationIntegration[];
  perkIntegrations: PerkIntegration[];
  triggerIntegrations: TriggerIntegration[];
  userEvents: UserEvent[];
  sync?: SyncIntegration;
};

export const Apps = [
  BazaarvoiceApp,
  BrevoApp,
  CohortApp,
  CohortFormApp,
  ComarchApp,
  DiscordApp,
  FacebookApp,
  GarminConnectApp,
  GoogleFitApp,
  InstagramApp,
  KlaviyoApp,
  MaxxingApp,
  PostgresqlApp,
  SalesforceApp,
  ShopifyApp,
  SpotifyApp,
  StravaApp,
  TalonOneApp,
  TikTokApp,
  TwitterApp,
  TypeformApp,
  YoutubeApp,
] as const;

// Medias
export type ContentMedia<T extends MediaStruct = MediaStruct> = {
  spec: MediaSpec<T>;
  configComponent: React.FC;
  getTitle: (t: TFunction<'translation', undefined, 'translation'>) => string;
  mediaContent: React.FC<MediaContentProps<T>>;
  icon: React.FC<{size: number; textColor?: string}>;
};

// Perk Integration
export type PerkIntegrationConfigComponentProps = {
  connectionId?: string;
};

export type PerkIntegration<T extends PerkIntegrationStruct = PerkIntegrationStruct> = {
  spec: PerkIntegrationSpec<T>;
  configComponent: React.FC<PerkIntegrationConfigComponentProps>;
  usageViewComponent: React.FC<PerkUsageViewComponentProps>;
  iconColor: string;
  backgroundColor: string;
};

// User Events
export type UserEventDetailComponentProps = {
  userEvent: UserEventADto;
};
export type UserEventDetailModalComponentProps = {
  userEvent: UserEventADto;
};

export type UserEvent<UE extends UserEventStruct = UserEventStruct> = {
  spec: UserEventSpec<UE>;
  detailComponent: React.FC<UserEventDetailComponentProps>;
  detailModalComponent?: React.FC<UserEventDetailModalComponentProps>;
};

// Trigger Integration
export type TriggerIntegrationConfigComponentProps = {
  formReturn: UseFormReturn<StepTriggerFormType>;
  connectionId?: string;
  trigger?: StepTriggerType;
};

export type TriggerIntegrationConfigForm<Config = unknown, ConfigForm = unknown> = {
  schema: z.ZodSchema<ConfigForm>;
  toForm(config: Config): ConfigForm;
  fromForm(form: ConfigForm, definedLanguages?: Array<Language>): Config;
};

export type TriggerIntegrationStepCompletionColumnsProps<T extends TriggerIntegrationStruct> = {
  columnHelper: ColumnHelper<StepCompletionADto>;
  config: T['Config'];
  connectionId?: string;
};

export type TriggerIntegrationStepCompletionsGridComponentProps<
  T extends TriggerIntegrationStruct,
> = {
  config: T['Config'];
  stepCompletions: StepCompletionADto[];
};

export type TriggerIntegration<
  T extends TriggerIntegrationStruct = TriggerIntegrationStruct,
  ConfigForm = unknown,
> = {
  spec: TriggerIntegrationSpec<T>;
  configComponent: React.FC<TriggerIntegrationConfigComponentProps>;
  stepCompletionTitleComponent: React.FC<{
    data: T['VerificationAttemptData'];
    completionSuccess: boolean;
  }>;
  stepCompletionContextComponent?: React.FC<{data: T['VerificationAttemptData']}>;
  configForm?: TriggerIntegrationConfigForm<T['Config'], ConfigForm>;
  configAssets?: Array<{
    name: Path<z.infer<TriggerIntegrationSpec<T>['configSchema']>>;
    nestedName?: string;
    type: AdminAssetKind;
  }>;
  stepCompletionsGridComponent?: React.FC<TriggerIntegrationStepCompletionsGridComponentProps<T>>;
  useStepCompletionColumns?: (
    context: TriggerIntegrationStepCompletionColumnsProps<T>
  ) => ColumnDef<StepCompletionADto, unknown>[];
  getTitle: (t: TFunction<'translation', undefined, 'translation'>) => string;
};

// Sync Integration
export type SyncConfigComponentProps = {
  connection: ConnectionADto;
};

export type SyncIntegration<T extends SyncIntegrationStruct = SyncIntegrationStruct> = {
  spec: SyncIntegrationSpec<T>;
  syncConfigComponent: React.FC<SyncConfigComponentProps>;
};

// Notification Integration
export const NotificationConfigurationSchema = z.object({
  merchantId: z.string().uuid(),
  userEventId: EventIdSchema,
  connectionId: z.string().uuid().nullable(),
  notificationIntegrationId: NotificationIntegrationIdSchema.nullable(),
  notificationIntegrationConfig: z.any(),
  resourceType: NotificationConfigurationResourceTypeSchema.nullable(),
  resourceId: z.string().uuid().nullable(),
});
export type NotificationConfigurationType = z.infer<typeof NotificationConfigurationSchema>;

export type EventResourcesAndProperties = {
  properties: z.ZodSchema;
  resources: Record<ResourceType, z.ZodSchema>;
};

export type NotificationIntegrationConfigComponentProps = {
  event: EventResourcesAndProperties;
};

export type NotificationIntegration<
  T extends NotificationIntegrationStruct = NotificationIntegrationStruct,
> = {
  spec: NotificationIntegrationSpec<T>;
  configComponent: React.FC<NotificationIntegrationConfigComponentProps>;
  configComponentValidationSchema?: z.ZodSchema;
};

export const getAppsWithTriggerIntegrations = (): App[] => {
  return Apps.filter(app => app.spec.triggerIntegrationSpecs.length > 0);
};

export const getAppsWithUserEvents = (): App[] => {
  return Apps.filter(app => app.spec.userEventSpecs.length > 0);
};

export const getAppsWithNotificationIntegrations = (): App[] => {
  return Apps.filter(app => app.spec.notificationIntegrationSpecs.length > 0);
};

export const getPerkIntegrations = (): PerkIntegration[] => {
  return compact(Apps.flatMap(app => app.perkIntegrations));
};

export const getTriggerIntegrations = (): TriggerIntegration[] => {
  return compact(Apps.flatMap(app => app.triggerIntegrations));
};

export const getMedias = (): ContentMedia[] => {
  return compact(Apps.flatMap(app => app.medias));
};

export const getNotificationIntegrations = (appId?: AppId): NotificationIntegration[] => {
  if (appId) {
    const app = Apps.find(app => app.spec.id === appId);
    return app ? app.notificationIntegrations : [];
  }

  return compact(Apps.flatMap(app => app.notificationIntegrations));
};

export const getUserEvents = (
  appId?: AppId,
  resourceType?: NotificationConfigurationResourceType
): UserEvent[] => {
  if (appId) {
    const app = Apps.find(app => app.spec.id === appId);
    const userEvents = app ? app.userEvents : [];
    return resourceType
      ? userEvents.filter(userEvent => userEvent.spec.id.startsWith(`${appId}.${resourceType}`))
      : userEvents;
  }

  const userEvents = compact(Apps.flatMap(app => app.userEvents));
  return resourceType
    ? userEvents.filter(userEvent =>
        // Tests if the userEvent.spec.id string starts with any string "xxx." followed by the resourceType.
        new RegExp(`^.+\\.${resourceType}`, 'u').test(userEvent.spec.id)
      )
    : userEvents;
};

export const resourceToUserEvents = {
  badge: [CohortBadgeGainedUserEvent, CohortBadgeLostUserEvent],
  digitalAssetCollection: [
    CohortDigitalAssetReceivedUserEvent,
    CohortDigitalAssetTransferredUserEvent,
  ],
  challenge: [
    CohortChallengeCompletedUserEvent,
    CohortNewChallengeStepAvailableUserEvent,
    CohortChallengeLeftUserEvent,
    CohortChallengeOptedInUserEvent,
    CohortChallengeStepCompletedUserEvent,
    CohortNewChallengeAvailableUserEvent,
  ],
  content: [CohortContentAvailableUserEvent, CohortContentViewedUserEvent],
  perk: [CohortPerkReceivedUserEvent, CohortPerkUsedUserEvent],
  store: [],
  airdrop: [],
} as const;

export function assertConnectionId(connectionId?: string): asserts connectionId is string {
  if (connectionId === undefined) {
    throw new Error('connectionId has not been provided');
  }
}
