import React, {useState} from 'react';
import {useInView} from 'react-intersection-observer';

type InViewLazyLoaderProps = React.ComponentProps<'div'>;

export const InViewLazyLoader: React.FC<InViewLazyLoaderProps> = ({children, ...props}) => {
  const [isPrinted, setIsPrinted] = useState(false);
  const {ref, inView} = useInView();

  // don't unmount the component once it's printed
  if (inView && !isPrinted) {
    setIsPrinted(true);
  }

  return (
    <div ref={ref} {...props}>
      {isPrinted ? children : null}
    </div>
  );
};
